import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import useMobileOperatingSystem from "./hooks/useMobileOperatingSystem";
import Journeyheader from "./components/journey/Journeyheader";
import Journeybody from "./components/journey/Journeybody";
import Journeyheaders from "./components/headers/Journey";
import Loading from "./components/common/Loading";
import { JourneyDataContext } from "./contexts/Journeydatacontext";
import Error from "./components/journey/micro/Error";
import BlueHeader from "./components/common/BlueHeader";
import { Helmet } from "react-helmet";

const Journey = () => {
  const { origin, destination } = useParams();

  const [journeyData, setJourneyData] = useState(null);
  const [originName, setOriginName] = useState("");
  const [destinationName, setDestinationName] = useState("");
  const os = useMobileOperatingSystem();
  const [disruptionLevel, setDisruptionLevel] = useState(null);
  const [stationNotFound, setStationNotFound] = useState(false);
  const [failedCRS, setFailedCRS] = useState("");
  const [loading, setLoading] = useState(true);

  const [allowRefresh, setAllowRefresh] = useState(true);

  const [error, setError] = useState(false);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);

  const fetchJourneyData = useCallback(async () => {
    try {
      const response = await Promise.race([
        fetch(
          `${process.env.REACT_APP_DBAPI_URL}/api/v1.0/getFormattedJourneyByCRS?sourceCRS=${origin}&destCRS=${destination}`
        ),
        new Promise((_, reject) =>
          setTimeout(() => reject(new Error("Timeout")), 5000)
        ),
      ]);
      const data = await response.json();
      if (response.status !== 200) {
        console.error("Bad status code returned from API", response.status);
        setError(true);
        return;
      }
      setJourneyData(data);
      if (data.data !== null) {
        const disruptionLevel = data.disruption_level || "none"; // set disruptionLevel to "none" if disruption_level is null
        setDisruptionLevel(disruptionLevel);
      }
      setHasLoadedOnce(true);
      setLastUpdated(new Date());
      setError(false);
    } catch (error) {
      console.error("Error fetching journey data:", error);
      setError(true);
    }
  }, [origin, destination]);

  const flashGreen = () => {
    const refreshData = document.getElementById("refresh-data");
    const tickRefreshData = document.getElementById("tick-refresh-data");
    const rotateIcon = document.getElementById("rotateicon");

    refreshData.classList.add("bg-green");
    tickRefreshData.classList.remove("d-none");
    rotateIcon.classList.add("d-none");

    setTimeout(() => {
      refreshData.classList.remove("bg-green");
      tickRefreshData.classList.add("d-none");
      rotateIcon.classList.remove("d-none");
    }, 1000);
  };

  const handleRefreshClick = () => {
    fetchJourneyData();

    const rotateIcon = document.getElementById("rotateicon");
    rotateIcon.classList.add("imageRot");
    rotateIcon.addEventListener("animationend", () => {
      rotateIcon.classList.remove("imageRot");
      flashGreen();
    });
  };

  function isDocumentVisible() {
    // Using the Page Visibility API
    return document.visibilityState === "visible";
  }

  const conditionalRefresh = useCallback(() => {
    if (isDocumentVisible()) {
      fetchJourneyData();
    }
  }, [fetchJourneyData]);

  useEffect(() => {
    function handleVisibilityChange() {
      conditionalRefresh();
    }

    document.addEventListener("visibilitychange", handleVisibilityChange);

    const intervalId = setInterval(conditionalRefresh, 5 * 60 * 1000);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearInterval(intervalId);
    };
  }, [conditionalRefresh]);

  const fetchStationName = async (crs, setName) => {
    try {
      const response = await Promise.race([
        fetch(
          `${process.env.REACT_APP_DBAPI_URL}/api/v1.0/stationCRSLookup?crs=${crs}`
        ),
        new Promise((_, reject) =>
          setTimeout(() => reject(new Error("Timeout")), 5000)
        ),
      ]);
      const data = await response.json();
      if (response.status === 404) {
        setStationNotFound(true);
        setFailedCRS(crs);
      } else {
        setName(data.stationName);
      }
    } catch (error) {
      console.error("Error fetching station name:", error);
      setError(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([
          fetchJourneyData(),
          fetchStationName(origin, setOriginName),
          fetchStationName(destination, setDestinationName),
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, [origin, destination, fetchJourneyData]);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 100);
  }, [loading]);

  return (
    <div>
      {!loading && (!error || hasLoadedOnce || stationNotFound) && (
        <JourneyDataContext.Provider
          value={{ fetchJourneyData, allowRefresh, setAllowRefresh }}
        >
          <Journeyheaders
            sourceCRS={origin}
            destCRS={destination}
            sourceStationName={originName}
            destStationName={destinationName}
          />
          {stationNotFound ? (
            <>
              <Helmet>
                <title>Error | departureboard.io</title>
              </Helmet>
              <BlueHeader title="Uh oh! We've got a problem" />
              <div className="mt-1 text-center">
                <p>
                  Oh no! We had an error looking up one of the stations you
                  provided.
                </p>

                <p>The station CRS "{failedCRS}" was invalid.</p>

                <p>
                  Try searching on the <a href="/">homepage</a> for your
                  journey.
                </p>
              </div>
            </>
          ) : (
            <>
              <Journeyheader
                sourceCRS={origin}
                destCRS={destination}
                sourceStationName={originName}
                destStationName={destinationName}
                os={os}
                disruptionLevel={disruptionLevel}
                journeyData={journeyData}
                lastUpdated={lastUpdated}
                error={error}
              />

              <Journeybody
                sourceCRS={origin}
                destCRS={destination}
                sourceStationName={originName}
                destStationName={destinationName}
                os={os}
                journeyData={journeyData}
              />
            </>
          )}
        </JourneyDataContext.Provider>
      )}
      {loading && !error && <Loading />}
      {error && !stationNotFound && !hasLoadedOnce && (
        <>
          <BlueHeader title="Journey Error" />
          <div className="container pb-5">
            <div className="row justify-content-center">
              <div className="col pt-2 text-center">
                {" "}
                Uh oh. There was an error loading your journey.
                <br />
                <br /> This is usually caused by a bad network connection or in
                some rarer cases an issue with National Rail where we source our
                data. Try hitting the refresh button below.
                <button
                  id="refresh-data"
                  type="button"
                  className="btn btn-ontheme align-items-center btn-block mt-4"
                  onClick={handleRefreshClick}
                >
                  <svg
                    id="rotateicon"
                    className="bi bi-arrow-clockwise"
                    width="1.3em"
                    height="1.3em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.17 6.706a5 5 0 017.103-3.16.5.5 0 10.454-.892A6 6 0 1013.455 5.5a.5.5 0 00-.91.417 5 5 0 11-9.375.789z"
                      clipRule="evenodd"
                    />
                    <path
                      fillRule="evenodd"
                      d="M8.147.146a.5.5 0 01.707 0l2.5 2.5a.5.5 0 010 .708l-2.5 2.5a.5.5 0 11-.707-.708L10.293 3 8.147.854a.5.5 0 010-.708z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <svg
                    id="tick-refresh-data"
                    className="bi bi-check d-none"
                    width="1.3em"
                    height="1.3em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M13.854 3.646a.5.5 0 010 .708l-7 7a.5.5 0 01-.708 0l-3.5-3.5a.5.5 0 11.708-.708L6.5 10.293l6.646-6.647a.5.5 0 01.708 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Journey;
