import React, { useState } from "react";
import StationSearchBox from "../stationsearchbox/Stationsearchbox";
import { Form } from "react-bootstrap";

function Searchbox() {
  const [departingCRS, setDepartingCRS] = useState("");
  const [arrivingCRS, setArrivingCRS] = useState("");
  const [error, setError] = useState(false);

  const handleDepartingCRSChange = (crs) => {
    setDepartingCRS(crs);
  };

  const handleArrivingCRSChange = (crs) => {
    setArrivingCRS(crs);
  };

  const handleLookupJourney = (e) => {
    e.preventDefault();
    if (departingCRS === "" || arrivingCRS === "") {
      setError(true);
    } else {
      setError(false);
      window.location.href = `/journey/${departingCRS}/${arrivingCRS}`
    }
  };

  return (
    <div className="block-color-header-blue gray">
      <div className="container">
        <div className="block-color-header-heading black">
          Live Train Departures. Designed for Commuters.
        </div>
        <div className="block-color-header-small black">
          Search for your regular journey. Add it to your homescreen, and you'll
          be able to access live train departure information in less than a
          second.
        </div>

        <Form>
          <div className="form-group">
            <StationSearchBox
              searchPrefix="Departing station..."
              id="departing-crs"
              onCRSChange={handleDepartingCRSChange}
            />
            {error && !departingCRS && (
              <div className="text-danger">
                Please enter a departing station.
              </div>
            )}
          </div>
          <div className="form-group">
            <StationSearchBox
              searchPrefix="Arriving station..."
              onCRSChange={handleArrivingCRSChange}
            />
            {error && !arrivingCRS && (
              <div className="text-danger">
                Please enter an arriving station.
              </div>
            )}
          </div>
          <button
            id="journeyload"
            type="button"
            className="btn btn-primary align-items-center btn-block"
            onClick={handleLookupJourney}
          >
            Lookup Journey
          </button>
        </Form>
      </div>
    </div>
  );
}

export default Searchbox;
