import React from "react";
import ArrivalDueOnBoard from "../journey/micro/ArrivalDueOnBoard";
import DepartureDueOnBoard from "../journey/micro/DepartureDueOnBoard";
import DepartureDueDelay from "../assets/img/DepartureDueDelay.svg";
import DepartureHappened from "../assets/img/DepartureHappened.svg";
import DepartureDueOnTime from "../assets/img/DepartureDueOnTime.svg";
import ArrivalDueOnTime from "../assets/img/ArrivalDueOnTime.svg";
import ArrivalDueDelay from "../assets/img/ArrivalDueDelay.svg";
import ArrivalHappened from "../assets/img/ArrivalHappened.svg";
import DepartDalayed from "../assets/img/DepartDelayed.svg";
import ArriveDelayed from "../assets/img/ArriveDelayed.svg";
import ArrivalCancelled from "../assets/img/ArrivalCancelled.svg";
import DepartureCancelled from "../assets/img/DepartureCancelled.svg";
import {
  renderCallingPointPlatform,
  renderCallingPointPlatformTitle,
} from "../utils/platformUtils";

export const TableRows = ({ apiData }) => {
  if (!apiData || !apiData.callingPoints) {
    return null;
    // Rest of the renderTableRows code...
  }
  return apiData.callingPoints.map((point, index) => (
    <div className="justify-content-left d-flex pb-2 pt-2 border-bottom" key={index}>
      {!point.isFirstCallingPoint ||
      (point.isFirstCallingPoint && point.scheduledArrival !== "N/A") ? (
        <div className="text-center align-self-center icon-column d-flex flex-column align-items-center text-center mr-3">
          {point.isCancelled ? (
            <img
              style={{ width: "1.5rem", height: "1.5rem" }}
              src={ArrivalCancelled}
              alt="Train arrival is cancelled icon"
            />
          ) : point.hasArrived || point.hasDeparted ? (
            <>
              {point.arrivedEarly ||
              point.actualArrival === "On time" ||
              (!point.hasArrived &&
                point.hasDeparted &&
                (point.departedEarly ||
                  point.actualDeparture === "On time")) ? (
                <img
                  style={{ width: "1.5rem", height: "1.5rem" }}
                  src={ArrivalHappened}
                  alt="Train has arrived icon"
                />
              ) : (
                <img
                  style={{ width: "1.5rem", height: "1.5rem" }}
                  src={ArriveDelayed}
                  alt="Train has arrived delayed icon"
                />
              )}
            </>
          ) : (
            <>
              {(point.arrivalDueEarly ||
              point.estimatedArrival === "On time" ||
              (point.estimatedArrival === "N/A" || 
                (point.scheduledDeparture === "On time" ||
                  point.departureDueEarly))) ? (
                <img
                  style={{ width: "1.5rem", height: "1.5rem" }}
                  src={ArrivalDueOnTime}
                  alt="Train arrival due on time icon"
                />
              ) : (
                <img
                  style={{ width: "1.5rem", height: "1.5rem" }}
                  src={ArrivalDueDelay}
                  alt="Train has arrived due delayed icon"
                />
              )}
            </>
          )}

          <div className=" mt-2 text-center cp-dep justify-content-center text-align-center align-content-center d-flex flex-column">
            <>
              <div className="p-9 align-self-center">
                <span className="depart-time text-center">
                  {point.scheduledArrival === "N/A"
                    ? point.scheduledDeparture
                    : point.scheduledArrival}
                </span>
              </div>
              <div className="p-9 align-self-center">
                <span className="depart-due sts-green text-center">
                  {point.scheduledArrival === "N/A" ? (
                    <DepartureDueOnBoard cp={point} />
                  ) : (
                    <ArrivalDueOnBoard cp={point} />
                  )}
                </span>
              </div>
            </>
          </div>
        </div>
      ) : (
        <div className="text-center align-self-center icon-column d-flex flex-column align-items-center text-center mr-3"></div>
      )}

      {!point.isUserArriveCRS ? (
        <div className="text-center mr-0 align-self-center icon-column mr-4">
          <div className="text-center mr-0 align-self-center icon-column mr-4">
            {point.isCancelled ? (
              <img
                style={{ width: "1.5rem", height: "1.5rem" }}
                src={DepartureCancelled}
                alt="Train departure was cancelled icon"
              />
            ) : point.hasDeparted ? (
              <>
                {point.departedEarly || point.actualDeparture === "On time" ? (
                  <img
                    style={{ width: "1.5rem", height: "1.5rem" }}
                    src={DepartureHappened}
                    alt="Train has departed on time icon"
                  />
                ) : (
                  <img
                    style={{ width: "1.5rem", height: "1.5rem" }}
                    src={DepartDalayed}
                    alt="Train has departeded delayed icon"
                  />
                )}
              </>
            ) : (
              <>
                {point.departureDueEarly ||
                point.estimatedDeparture === "On time" ||
                (point.estimatedDeparture === "N/A" &&
                  (point.arrivalDueEarly ||
                    point.estimatedArrival === "On time")) ? (
                  <img
                    style={{ width: "1.5rem", height: "1.5rem" }}
                    src={DepartureDueOnTime}
                    alt="Train is due to depart on time icon"
                  />
                ) : (
                  <img
                    style={{ width: "1.5rem", height: "1.5rem" }}
                    src={DepartureDueDelay}
                    alt="Train is due to depart delayed icon"
                  />
                )}
              </>
            )}
          </div>

          <div className=" mt-2 text-center cp-dep justify-content-center text-align-center align-content-center d-flex flex-column">
            <>
              <div className="p-9 align-self-center">
                <span className="depart-time text-center">
                  {point.scheduledDeparture === "N/A"
                    ? point.scheduledArrival
                    : point.scheduledDeparture}
                </span>
              </div>
              <div className="p-9 align-self-center">
                <span className="depart-due sts-green text-center">
                  {point.scheduledDeparture === "N/A" ? (
                    <ArrivalDueOnBoard cp={point} />
                  ) : (
                    <DepartureDueOnBoard cp={point} />
                  )}
                </span>
              </div>
            </>
          </div>
        </div>
      ) : (
        <div className="text-center align-self-center icon-column d-flex flex-column align-items-center text-center mr-4"></div>
      )}

      <div className="col-pad-override col my-auto serviceinfo">
        <div className="p-9 align-self-center">
          <span className="train-final-dest">{point.stationName}</span>
        </div>
        <div className="p-9 align-self-center">
          <span className="train-user-journey-dest">
            Station Code: {point.crs}
          </span>
        </div>
      </div>
      <div className="ml-auto text-center cp-plat justify-content-center align-content-center d-flex flex-column">
        <div className="p-9 align-self-center">
          <span className="plat-title">
            {renderCallingPointPlatformTitle(point)}
          </span>
        </div>
        <div className="p-9 align-self-center">
          <span className="plat-number">
            {renderCallingPointPlatform(point)}
          </span>
        </div>
      </div>
    </div>
  ));
};
