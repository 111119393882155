import React, { createContext, useContext, useEffect, useState } from "react";
import { auth, signInWithCustomToken } from "../Firebase";
import axios from "axios";
import DeviceUtils from "../components/utils/deviceType";
import {
  getFirestore,
  doc,
  onSnapshot,
  getDoc,
  setDoc,
} from "firebase/firestore";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  let initialUser = null;
  let initialUserDocument = null;
  const storedUser = localStorage.getItem('currentUser');
  const storedUserDocument = localStorage.getItem('userDocument');

  if (storedUser) {
    initialUser = JSON.parse(storedUser);
  }
  if (storedUserDocument) {
    initialUserDocument = JSON.parse(storedUserDocument);
  }

  const [currentUser, setCurrentUser] = useState(initialUser);
  const [userDocument, setUserDocument] = useState(initialUserDocument);
  const [loading, setLoading] = useState(true);

  const getProviderName = (user) => {
    if (user && user.providerData && user.providerData.length > 0) {
      const providerId = user.providerData[0].providerId;
      const providerName = providerId.split(".")[0];
      return providerName;
    }
    return null;
  };

  useEffect(() => {
    const createUserDocument = async (user) => {
      const firestore = getFirestore();
      const userRef = doc(firestore, "Users", user.uid);

      const userData = {
        uid: user.uid,
        email: user.email,
        isPro: false,
        idp: getProviderName(user),
      };

      await setDoc(userRef, userData);
    };

    if (!currentUser) {
      const queryParams = new URLSearchParams(window.location.search);
      const key = queryParams.get("key");
      const userID = queryParams.get("userID");
      const token = queryParams.get("token");

      if (key && userID && token && DeviceUtils.isIOSStandalonePWA()) {
        axios
          .post(
            `${process.env.REACT_APP_DBAPI_URL}/api/v1.0/mintLoginTransfer`,
            {
              key,
              userID,
              token,
            }
          )
          .then((response) => {
            const { token: firebaseToken } = response.data;
            console.log(token);
            return signInWithCustomToken(auth, firebaseToken)
              .then((userCredential) => {
                console.log("User successfully signed in: ", userCredential.user);
                setCurrentUser(userCredential.user);
                localStorage.setItem('currentUser', JSON.stringify(userCredential.user));
              })
              .catch((error) => {
                console.error("Error during Firebase sign-in:", error);
              });
          })
          .catch((error) => {
            console.error("Error during login transfer:", error);
          });
      }
    } else {
      const firestore = getFirestore();
      const userRef = doc(firestore, "Users", currentUser.uid);

      const unsubscribe = onSnapshot(
        userRef,
        async (docSnapshot) => {
          if (docSnapshot.exists()) {
            setUserDocument(docSnapshot.data());
            localStorage.setItem('userDocument', JSON.stringify(docSnapshot.data()));
            setLoading(false);
          } else {
            await createUserDocument(currentUser);
            const newUserSnapshot = await getDoc(userRef);
            if (newUserSnapshot.exists()) {
              setUserDocument(newUserSnapshot.data());
              localStorage.setItem('userDocument', JSON.stringify(newUserSnapshot.data()));
              setLoading(false);
            } else {
              console.error("User document not found");
            }
          }
        },
        (error) => {
          console.error("Error fetching user document:", error);
        }
      );

      const storedUserDocument = localStorage.getItem('userDocument');
      if (storedUserDocument) {
        setUserDocument(JSON.parse(storedUserDocument));
        setLoading(false);
      }

      return () => {
        unsubscribe();
      };
    }
  }, [currentUser]);

  useEffect(() => {
    const authUnsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (!user) {
        setUserDocument(null);
        setLoading(false);
        localStorage.removeItem('currentUser');
        localStorage.removeItem('userDocument');
      }
    });

    return () => {
      authUnsubscribe();
    };
  }, []);

  const contextValue = {
    currentUser,
    userDocument,
    loading,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
