import React, { useEffect } from "react";

const GoogleAd = () => {
//   useEffect(() => {
//     (window.adsbygoogle = window.adsbygoogle || []).push({});
//   }, []);

  return (
    <>

      {/* <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-9252580837920643"
        data-ad-slot="3104574551"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins> */}
    </>
  );
};

export default GoogleAd;
