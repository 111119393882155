import React, { useState, useCallback } from "react";
import StationSearchBox from "../home/stationsearchbox/Stationsearchbox";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import { useAuth } from "../../contexts/Authcontext";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import Loading from "../common/Loading";

function Searchbox({
  setShowSearchJourney,
  selfRefer,
  noDocument,
  isOutdated,
}) {
  const { currentUser } = useAuth(); // Use the hook to get currentUser and userDocument
  const [departingCRS, setDepartingCRS] = useState("");
  const [arrivingCRS, setArrivingCRS] = useState("");
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [error, setError] = useState(false);
  const [journeyList, setJourneyList] = useState([]);
  const [loadingJourneys, setLoadingJourneys] = useState(true);
  const [errorLoadingJourneys, setErrorLoadingJourneys] = useState(true);
  const [errorSettingOnboard, seterrorSettingOnboard] = useState(false);
  const [NoMatchingServices, setNoMatchingServices] = useState(false);

  const setOnboardDocument = useCallback(
    async (journey, e) => {
      e.preventDefault();
      const firestore = getFirestore();
      const docRef = doc(
        firestore,
        "Users",
        currentUser.uid,
        "onboard",
        "trip"
      );
      const journeyData = {
        rid: journey.rid,
        sourceCRS: journey.sourceCRS,
        destCRS: journey.destCRS,
        departureScheduled: journey.departureTime,
        trainOperator: journey.operator,
        terminates: journey.terminates,
        sourceName: journey.sourceFullName,
        destName: journey.destFullName,
        timestamp: new Date(),
      };

      try {
        await setDoc(docRef, journeyData);
      } catch (error) {
        seterrorSettingOnboard(true);
        console.error("Error setting onboard document:", error);
      }
      seterrorSettingOnboard(false);
      setShowSearchJourney(false);
    },
    [currentUser, setShowSearchJourney]
  );

  const handleDepartingCRSChange = (crs) => {
    setDepartingCRS(crs);
  };

  const handleCancelSearch = () => {
    setShowSearchJourney(false);
  };

  const handleArrivingCRSChange = (crs) => {
    setArrivingCRS(crs);
  };

  const handleLookupJourney = async (e) => {
    e.preventDefault();

    if (departingCRS === "" || arrivingCRS === "") {
      setError(true);
    } else {
      setSearchPerformed(true);
      setError(false);
      setLoadingJourneys(true);
      setErrorLoadingJourneys(false);

      try {
        const response = await Promise.race([
          axios.get(
            `${process.env.REACT_APP_DBAPI_URL}/api/v1.0/getJourneyListByCRS?sourceCRS=${departingCRS}&destCRS=${arrivingCRS}`
          ),
          new Promise((resolve, reject) => {
            setTimeout(() => reject(new Error("Request timeout")), 6000);
          }),
        ]);

        if (response.status === 200) {
          setJourneyList(response.data.data);

          if (response.data.data === null) {
            setNoMatchingServices(true);
          } else {
            setNoMatchingServices(false);
          }
        } else {
          setErrorLoadingJourneys(true);
        }
      } catch (error) {
        console.error("Error fetching journey list:", error);
        setErrorLoadingJourneys(true);
      } finally {
        setLoadingJourneys(false);
      }
    }
  };

  return (
    <div className="container">
      <div className="border-bottom mb-2 pt-4 pb-0">
        <h5 className="mb-2">Journey Search</h5>
      </div>
      {noDocument ? (
        <div className="pb-3">
          You don't have a journey saved to the Onboard Hub. You can add
          journeys to the Onboard Hub from the departure view. Or just search
          for your service below.
        </div>
      ) : isOutdated ? (
        <div className="pb-3">
          The journey you saved to the Onboard Hub has expired. Please search
          for a new journey below.
        </div>
      ) : (
        <div className="pb-3">
          Enter your departing and arriving stations below to search for
          services that can be added to the Onboard Hub.
        </div>
      )}

      <Form>
        <div className="form-group">
          <StationSearchBox
            searchPrefix="Departing station..."
            id="departing-crs"
            onCRSChange={handleDepartingCRSChange}
          />
          {error && !departingCRS && (
            <div className="text-danger">Please enter a departing station.</div>
          )}
        </div>
        <div className="form-group">
          <StationSearchBox
            searchPrefix="Arriving station..."
            onCRSChange={handleArrivingCRSChange}
          />
          {error && !arrivingCRS && (
            <div className="text-danger">Please enter an arriving station.</div>
          )}
        </div>
        <div className="row">
          <div className={selfRefer ? "col pr-3 pl-3" : "col"}>
            <button
              id="journeyload"
              type="button"
              className="btn btn-ontheme align-items-center btn-block"
              onClick={handleLookupJourney}
            >
              Search Journey
            </button>
          </div>
          {selfRefer && (
            <div className="col pl-1">
              <button
                id="journeycancel"
                type="button"
                className="btn btn-ontheme align-items-center btn-block"
                onClick={handleCancelSearch}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </Form>

      {searchPerformed && (
        <div className="border-bottom mb-2 pt-5 pb-0">
          <h5 className="mb-2">Services</h5>
        </div>
      )}

      {searchPerformed && !loadingJourneys && !errorLoadingJourneys ? (
        <div className="pt-2 pb-2">
          {!NoMatchingServices ? (
            <>
              {errorSettingOnboard && (
                <div className="sts-red pb-3 text-center">
                  There was an error settting your Onboard Service. Please check
                  your network and try again.
                </div>
              )}
              <table className="table">
                <thead>
                  <tr>
                    <th className="bg-ontheme">Dep.</th>
                    <th className="bg-ontheme">Destination</th>
                    <th className="bg-ontheme">Onboard</th>
                  </tr>
                </thead>
                <tbody>
                  {journeyList.map((journey) => (
                    <tr key={journey.rid}>
                      <td className="pt-2 pb-2 text-center align-middle">
                        {journey.departureTime}
                      </td>
                      <td className="pt-2 pb-2 align-middle">
                        <div className="train-final-dest">
                          {journey.terminates !== journey.destFullName ? (
                            <>
                              <div>{journey.terminates}</div>
                              <div className="train-user-journey-dest">
                                Calling at {journey.destFullName}
                              </div>
                            </>
                          ) : (
                            <div>{journey.terminates}</div>
                          )}
                        </div>
                        <div className="train-user-journey-dest">
                          {journey.operator} Service
                        </div>
                      </td>

                      <td className="pt-2 pb-2 text-center align-middle ">
                        <Button
                          id="setOnboard"
                          type="button"
                          className="btn btn-ontheme align-items-center btn-block"
                          onClick={(e) => setOnboardDocument(journey, e)}
                        >
                          <ArrowForwardIcon />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <div className="text-center">
              There were no matching services found for your search. Remember,
              we only show services that go directly between two stations.
            </div>
          )}
        </div>
      ) : (
        <>
          {searchPerformed && (
            <>
              {loadingJourneys ? (
                <Loading />
              ) : errorLoadingJourneys ? (
                <>
                  <div className="text-center">
                    There was an error loading the available journeys. This is
                    usually caused by a bad network connection or in some rarer
                    cases an issue with National Rail where we source our data.
                  </div>
                  <div className="text-center pt-3">
                    Please try your search again.
                  </div>
                </>
              ) : (
                <div>
                  There's currently no services running between these two
                  stations. As a reminder, we only show services that go
                  directly between stations so you might be able to find a route
                  with a change.
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Searchbox;
