import React, { useState, useEffect } from "react";

import axios from "axios";
import AsyncSelect from "react-select/async";

const StationSearchBox = (props) => {
  const [selectedStation, setSelectedStation] = useState(null);

  const { onCRSChange } = props;

  useEffect(() => {
    if (selectedStation) {
      onCRSChange(selectedStation.value);
    } else {
      onCRSChange("");
    }
  }, [selectedStation, onCRSChange]);

  const loadOptions = async (inputValue) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DBAPI_URL}/api/v1.0/stationSearch?searchString=${
          inputValue.length > 1 ? inputValue : ""
        }`
      );
      const formattedStations = response.data.stations.map((station) => ({
        value: station.stationCRS,
        label: station.stationName,
      }));
      return formattedStations;
    } catch (error) {
      console.error("Error searching stations:", error);
      return [];
    }
  };

  return (
    <div>
      <AsyncSelect
        value={selectedStation}
        onChange={(selectedOption) => setSelectedStation(selectedOption)}
        loadOptions={loadOptions}
        defaultOptions
        isSearchable={true}
        isClearable={true}
        placeholder={props.searchPrefix}
      />
    </div>
  );
};

export default StationSearchBox;
