import React, { useState } from "react";
import Commonheader from "./components/headers/Common";
import { useAuth } from "./contexts/Authcontext";
import { format } from "date-fns";
import Loading from "./components/common/Loading";
import axios from "axios";
import BlueHeader from "./components/common/BlueHeader";

const Profile = () => {
  const { currentUser, userDocument, loading: authLoading } = useAuth();

  React.useEffect(() => {
    if (!authLoading && !currentUser) {
      window.location.href =
        "/login?referURL=" + encodeURIComponent("/profile");
    }
  }, [currentUser, authLoading]);

  const [loading, setLoading] = useState(false);

  const handleManageSubscription = async () => {
    setLoading(true);
    try {
      const authToken = await currentUser.getIdToken();
      const response = await axios.post(
        `${process.env.REACT_APP_DBAPI_URL}/api/v1.0/generateStripeCustomerPortalLink`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setLoading(false);
      const { url } = response.data;
      window.location.href = url;
    } catch (error) {
      setLoading(false);
      console.error("Error generating management link:", error);
    }
  };

  return (
    <>
      {" "}
      <BlueHeader title="My Profile" />
      <div className="container-fluid pb-5">
        <Commonheader page="Profile" />

        {currentUser && userDocument ? (
          <>
            <div className="row justify-content-center">
              <div className="col-md-6">
                <h3 className="pt-3 pb-2">User Information</h3>

                <p>
                  You are logged in with{" "}
                  {userDocument.idp.charAt(0).toUpperCase() +
                    userDocument.idp.slice(1)}
                  . If you need to make changes to your email or password, you
                  can do so there.
                </p>
                <div className="form-group mt-4">
                  <label htmlFor="emailAddress" className="font-weight-bold">
                    Your Email:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="subscriptionStatus"
                    value={userDocument.email}
                    readOnly
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center pt-3 pb-2 mb-2 mt-4">
                  <h3 className="mb-0">Pro</h3>
                  {userDocument.isPro && (
                    <button
                      className="btn btn-sm btn-outline-primary"
                      onClick={handleManageSubscription}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Manage"}
                    </button>
                  )}
                </div>
                {userDocument.isPro ? (
                  <div>
                    <p>
                      Horrah! You're a departureboard.io Pro member, meaning you
                      get a whole host of additional features to help optimise
                      your commute.
                    </p>
                    <p>
                      Need to manage your subscription? Just hit the "Manage"
                      button up above.
                    </p>
                    <div className="form-group mt-4">
                      <label
                        htmlFor="subscriptionStatus"
                        className="font-weight-bold"
                      >
                        {userDocument.cancelAtEndPeriod
                          ? "Subscription Ends"
                          : "Subscription Renews"}
                        :
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="subscriptionStatus"
                        value={format(
                          new Date(userDocument.endPeriod.seconds * 1000),
                          "EEEE do MMMM yyyy"
                        )}
                        readOnly
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <p>
                      Oh no! You're not a departureboard.io Pro member, meaning
                      you're missing out on a whole host of additional features
                      to help optimise your commute.
                    </p>
                    <p>
                      To learn more and subscribe, just{" "}
                      <a href="/pro">click here</a>.
                    </p>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
};

export default Profile;
