import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { auth as firebaseAuth } from "../../Firebase";
import "firebase/auth";
import DeviceUtils from "../utils/deviceType";

const Journeyheader = ({
  sourceCRS,
  destCRS,
  sourceStationName,
  destStationName,
}) => {
  const [webManifestUrl, setWebManifestUrl] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authCheckComplete, setAuthCheckComplete] = useState(false);

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
        user.getIdToken().then((token) => {
          const manifestUrl = `${process.env.REACT_APP_DBAPI_URL}/api/v1.0/generateWebManifest.json?sourceCRS=${sourceCRS}&destCRS=${destCRS}&authtoken=${token}`;
          setWebManifestUrl(manifestUrl);
        });
      } else {
        setIsLoggedIn(false);
        setWebManifestUrl("");
      }
      setAuthCheckComplete(true);
    });

    return () => {
      unsubscribe();
    };
  }, [sourceCRS, destCRS]);

  return (
    <Helmet>
      <title>
        {`Live Departures from ${sourceStationName} to ${destStationName} |
        departureboard.io`}
      </title>
      <meta
        name="apple-mobile-web-app-title"
        content={`${sourceCRS} to ${destCRS}`}
      />
      <meta name="application-name" content={`${sourceCRS} to ${destCRS}`} />
      <meta
        property="og:description"
        content={`Live train departure information between ${sourceStationName} and ${destStationName} by departureboard.io`}
      />
      <meta
        name="description"
        content={`Live train departure information between ${sourceStationName} and ${destStationName} by departureboard.io`}
      />
      <meta
        name="og:title"
        content={`Live Departures: ${sourceStationName} to ${destStationName} | departureboard.io`}
      />
      <meta
        name="og:url"
        content={`https://departureboard.io/journey/${sourceCRS}/${destCRS}`}
      />

      {authCheckComplete ? (
        isLoggedIn && webManifestUrl && DeviceUtils.isIOS() ? (
          <link rel="manifest" href={webManifestUrl} />
        ) : (
          <link rel="manifest" href="/manifest/site.webmanifest" />
        )
      ) : null}
    </Helmet>
  );
};

export default Journeyheader;
