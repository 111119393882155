import React, { useState, useEffect, useCallback } from "react";
import Commonheader from "./components/headers/Common";
import axios from "axios";
import { useAuth } from "./contexts/Authcontext";
import { useLocation } from "react-router-dom";
import BlueHeader from "./components/common/BlueHeader";
import DeviceUtils from "./components/utils/deviceType";

const Pro = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const referURL = query.get("referURL");

  const [loading, setLoading] = useState(false);

  const { currentUser, userDocument } = useAuth();
  const [paymentIntentURL, setPaymentIntentURL] = useState(null);
  const [paymentIntentURLError, setPaymentIntentURLError] = useState(false);
  const [paymentIntentURLLoading, setPaymentIntentURLLoading] = useState(true);

  const handleGeneratePaymentIntent = useCallback(async () => {
    try {
      const authToken = await currentUser.getIdToken();
      let reqUrl = `${process.env.REACT_APP_DBAPI_URL}/api/v1.0/generatePaymentIntent`;

      if (referURL) {
        reqUrl += `?referURL=${encodeURIComponent(referURL)}`;
      }

      const response = await axios.post(
        reqUrl,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const { url } = response.data;
      setPaymentIntentURL(url);
      setPaymentIntentURLLoading(false);
      setPaymentIntentURLError(false);
    } catch (error) {
      console.error("Error generating payment intent:", error);
      setPaymentIntentURLLoading(false);
      setPaymentIntentURLError(true);
    }
  }, [currentUser, referURL]); // Add dependencies here

  useEffect(() => {
    if (
      DeviceUtils.isIOSStandalonePWA &&
      currentUser &&
      !currentUser.isPro &&
      userDocument &&
      userDocument.stripeCustomerID
    ) {
      handleGeneratePaymentIntent();
    }
  }, [currentUser, userDocument, handleGeneratePaymentIntent]);

  const handleSubscribeToPro = async () => {
    setLoading(true);
    try {
      const authToken = await currentUser.getIdToken();

      let reqUrl = `${process.env.REACT_APP_DBAPI_URL}/api/v1.0/generatePaymentIntent`;

      if (referURL) {
        reqUrl += `?referURL=${encodeURIComponent(referURL)}`;
      }

      const response = await axios.post(
        reqUrl,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setLoading(false);
      const { url } = response.data;
      window.location.href = url;
    } catch (error) {
      setLoading(false);
      console.error("Error generating payment intent:", error);
    }
  };

  const handleLogin = async () => {
    window.location.href =
      "/login?referURL=" + encodeURIComponent(window.location.href);
  };

  return (
    <>
      <BlueHeader title="Pro" />
      <Commonheader page="Pro" />

      <div className="container pb-5">
        <div className="">
          {(userDocument && !userDocument.isPro) || !userDocument ? (
            <div className="featured-bg gray black border pl-2 pr-2">
              <div className="text-center pb-4 black">
                Pro gives you a whole host of new features and capabilities to
                optimise your commute.
              </div>
              <div className="text-center pb-4 black">
                Free for 7 days and then just <b>£1.99/month</b>. Cancel at any
                time.
              </div>
              <div className="row d-flex justify-content-center ml-2 mr-2">
                {currentUser ? (
                  <>
                    {!userDocument || !userDocument.stripeCustomerID ? (
                      <>
                        <div class="loader"></div>
                        <div className="text-center pb-1 pl-2 pt-1 black">
                          Loading Subscription Button
                        </div>
                      </>
                    ) : !DeviceUtils.isIOSStandalonePWA() ? (
                      <button
                        className="btn btn-ontheme btn-block"
                        aria-label="Subscribe to Pro"
                        onClick={handleSubscribeToPro}
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Subscribe"}
                      </button>
                    ) : (
                      <>
                        {!paymentIntentURLLoading && !paymentIntentURLError ? (
                          <a
                            className="btn btn-ontheme btn-block"
                            href={paymentIntentURL}
                            role="button"
                          >
                            Subscribe
                          </a>
                        ) : paymentIntentURLError ? (
                          <div className="text-center pb-1 pt-1 sts-red">
                            There was an error generating the subscription link.
                            Please <a href="/contact">contact us</a> if this
                            error persists.
                          </div>
                        ) : (
                          <div className="text-center pb-1 pt-1 black">
                            Loading...
                          </div>
                        )}
                      </>
                    )}
                    <div className="text-center col-12 pb-2 pt-4 black">
                      Once you subscribe, you'll have instant access to the
                      below features.
                    </div>
                    <div className="text-center col-12 pb-2 pt-1 pt-0 small black">
                      Subscriptions are subject to our{" "}
                      <a href="/terms">Terms and Conditions</a>.
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-center pb-3 black">
                      Before you can subscribe to Pro, you'll need to login.
                    </div>

                    <button
                      className="btn btn-ontheme btn-block"
                      onClick={handleLogin}
                    >
                      Login
                    </button>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="featured-bg gray black border">
              <div className="text-center pb-1 pr-1 pl-1 black">
                Thanks for being a Pro Member! You can manage your subscription
                in your <a href="/profile">profile page</a>.
              </div>
            </div>
          )}

          <div className="pt-4"></div>
          <div className="featured-bg gray black">
            <div className="container black">
              <div className="row">
                <div className="align-self-center col-md-12 col-lg-6 pt-1">
                  <h3>Predicted Platforms</h3>
                  <p className="pt-1">
                    Beat the rush and find out what platform your train is
                    departing from before the general public.
                  </p>
                  <p className="d-none d-md-block pt-1">
                    When subscribed to Pro, you'll see predicted platforms up to
                    2 hours early.
                  </p>
                </div>
                <div className="col-md-12 col-lg-6 pt-2">
                  <img
                    id="mockup-gif"
                    className="image-cap"
                    src="/img/pred_plat.png"
                    alt="Predicted platforms"
                  />
                </div>
                <div className="col-md-12 d-block d-md-none">
                  <p className="pt-2">
                    When subscribed to Pro, you'll see predicted platforms up to
                    2 hours early.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="featured-bg gray black">
            <div className="container black">
              <div className="row">
                <div className="align-self-center col-md-12 col-lg-6 pt-1">
                  <h3>Expanded Services</h3>
                  <p className="pt-1">
                    Click on any service to expand and see more information,
                    including Calling Points, and delay and cancellation
                    reasons.
                  </p>
                  <p className="d-none d-md-block pt-1">
                    Expanded Services also supports dividing and joining trains.
                  </p>
                </div>
                <div className="col-md-12 col-lg-6 pt-2">
                  <img
                    id="mockup-gif"
                    className="image-cap"
                    src="/img/calling_points.png"
                    alt="Calling points"
                  />
                </div>
                <div className="col-md-12 d-block d-md-none">
                  <p className="pt-2">
                    Expanded Services also supports dividing and joining trains.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="featured-bg gray black">
            <div className="container black">
              <div className="row">
                <div className="align-self-center col-md-12 col-lg-6 pt-1">
                  <h3>Onboard Hub</h3>
                  <p className="pt-1">
                    Mark yourself as onboard a service, and access it from the
                    Onboard Hub where you can track your journey as it
                    progresses.
                    <br />
                    <br /> You can also share journeys with family and friends
                    so they can follow along.
                  </p>
                  <p className="d-none d-md-block pt-1">
                    Save the Onboard Hub to your homescreen for instant progress
                    updates.
                  </p>
                </div>
                <div className="col-md-12 col-lg-6 pt-2">
                  <img
                    id="mockup-gif"
                    className="image-cap"
                    src="/img/onboard_hub.png"
                    alt="Onboard Hub"
                  />
                </div>
                <div className="col-md-12 d-block d-md-none">
                  <p className="pt-2">
                    Save the Onboard Hub to your homescreen for instant progress
                    updates.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="featured-bg gray black">
            <div className="container black">
              <div className="row">
                <div className="align-self-center col-md-12 col-lg-6 pt-1">
                  <h3>Favourites</h3>
                  <p className="pt-1">
                    Don't want an icon for every journey? Have just one with
                    Favourites.
                  </p>
                  <p className="d-none d-md-block pt-1">
                    Quickly access your journey in either direction with just
                    one tap.
                  </p>
                </div>
                <div className="col-md-12 col-lg-6 pt-2">
                  <img
                    id="mockup-gif"
                    className="image-cap"
                    src="/img/favourites_hub.png"
                    alt="Favourites Hub"
                  />
                </div>
                <div className="col-md-12 d-block d-md-none">
                  <p className="pt-2">
                    Quickly access your journey in either direction with just
                    one tap.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="featured-bg gray black">
            <div className="container black">
              <div className="row">
                <div className="align-self-center col-12 pt-1 text-center">
                  <h3>More Coming Soon</h3>
                  <p className="pt-1">
                    We have a range of additional features coming soon. You'll
                    have instant access as soon as they ship.
                  </p>
                  <h5>Complex Journeys</h5>
                  <p>
                    You'll no longer be limited to point to point journeys. Tell
                    us your departing and arriving stations and we'll figure out
                    a way for you to get there.
                  </p>
                  <h5>Proximity Stations</h5>
                  <p>
                    Heading to London or another major city and not sure where
                    your train will arrive? Just tell us your city and we'll
                    show all suitable arriving stations.
                  </p>
                  <h5>More Services</h5>
                  <p>
                    We'll allow you to see departures further ahead than the
                    current limitation of the next 10 services in the next 2
                    hours.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pro;
