import React from "react";
import JourneyTable from "./Journeytable";
import Buttonbar from "./Buttonbar";
import NoServices from "./micro/NoServices";

const Journeybody = ({
  sourceCRS,
  destCRS,
  journeyData,
  sourceStationName,
  destStationName,
}) => {
  return (
    <div>
      <div>
        <div className="container pt-3">
          <Buttonbar
            sourceCRS={sourceCRS}
            destCRS={destCRS}
            sourceName={sourceStationName}
            destName={destStationName}
          ></Buttonbar>
          {journeyData && journeyData.data ? (
            <JourneyTable journeys={journeyData.data} />
          ) : (
            <NoServices />
          )}
        </div>
      </div>
    </div>
  );
};

export default Journeybody;
