import React, { useEffect, useState, useCallback } from "react";
import OtherHeader from "./components/headers/Other";
import ShareHeader from "./components/onboard/ShareHeader";
import { useAuth } from "./contexts/Authcontext";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import { setElementWidth } from "./components/utils/width";
import { TableRows } from "./components/onboard/TableRows";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import HelpIcon from "@mui/icons-material/Help";
import Loading from "./components/common/Loading";
import HelpHeader from "./components/onboard/HelpHeader";
import Search from "./components/onboard/Searchbox";
import NotPro from "./components/common/NotPro";
import OnboardHeader from "./components/onboard/OnboardHeader";
import BlueHeader from "./components/common/BlueHeader";

const Onboard = () => {
  const { currentUser, userDocument } = useAuth();
  const [loadingDocument, setLoadingDocument] = useState(true);
  const [loadingAPI, setLoadingAPI] = useState(true);
  const [errorLoadingAPI, seterrorLoadingAPI] = useState(true);
  const [apiHasLoadedOnce, setapiHasLoadedOnce] = useState(false);
  const [docData, setDocData] = useState(null);
  const [noDocument, setNoDocument] = useState(false);
  const [apiData, setApiData] = useState(null);
  const db = getFirestore();
  const [ShowHelpHeader, setShowHelpHeader] = useState(false);
  const [ShowShareJourney, setShowShareJourney] = useState(false);
  const [ShowSearchJourney, setShowSearchJourney] = useState(false);
  const [SelfRefer, setSelfRefer] = useState(false);
  const [IsOutdated, setIsOutdated] = useState(false);
  const [errorLoadingDocument, setErrorLoadingDocument] = useState(false);
  const [documentHasLoadedOnce, setDocumentHasLoadedOnce] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);

  const [shareLink, setShareLink] = useState(null);

  useEffect(() => {
    setElementWidth(".cp-dep");
    setElementWidth(".cp-plat");
    setElementWidth(".icon-column");
  }, [apiData, ShowSearchJourney]);

  useEffect(() => {
    if (currentUser) {
      const docRef = doc(db, "Users", currentUser.uid, "onboard", "trip");

      const unsubscribe = onSnapshot(
        docRef,
        (doc) => {
          if (doc.exists()) {
            const documentData = doc.data();
            setDocData(documentData);
            setNoDocument(false);
            setLoadingDocument(false);

            // Assuming the timestamp field in your document is 'timestamp'
            const timestamp = documentData.timestamp;
            const timestampDate = timestamp.toDate(); // Convert firebase timestamp to JavaScript Date
            const difference = Math.abs(new Date() - timestampDate) / 1000 / 60; // Difference in minutes
            setIsOutdated(difference > 1080);
          } else {
            setNoDocument(true);
            setSelfRefer(false);
            setLoadingDocument(false);
            setLoadingAPI(false);
            setIsOutdated(false);
          }
          setDocumentHasLoadedOnce(true);
          setLoadingDocument(false);
        },
        (err) => {
          setLoadingDocument(false);
          setErrorLoadingDocument(true);
          setLoadingAPI(false);
          setIsOutdated(false);
        }
      );

      return () => unsubscribe();
    } else {
      setLoadingAPI(false);
      setLoadingDocument(false);
      setIsOutdated(false);
    }
  }, [currentUser, db]);

  const handleCloseHelpHeader = () => {
    setShowHelpHeader(false);
  };

  const handleCloseShareJourneyHeader = () => {
    setShowShareJourney(false);
  };

  const flashGreen = () => {
    const refreshData = document.getElementById("refresh-data");
    const tickRefreshData = document.getElementById("tick-refresh-data");
    const rotateIcon = document.getElementById("rotateicon");

    refreshData.classList.add("bg-green");
    tickRefreshData.classList.remove("d-none");
    rotateIcon.classList.add("d-none");

    setTimeout(() => {
      setAllowRefresh(true);
    }, 5000);

    setTimeout(() => {
      refreshData.classList.remove("bg-green");
      tickRefreshData.classList.add("d-none");
      rotateIcon.classList.remove("d-none");
    }, 1000);
  };

  const [allowRefresh, setAllowRefresh] = useState(true);

  const handleRefreshClick = () => {
    if (allowRefresh) {
      setAllowRefresh(false);
      fetchJourneyData();
    }

    const rotateIcon = document.getElementById("rotateicon");
    rotateIcon.classList.add("imageRot");
    rotateIcon.addEventListener("animationend", () => {
      rotateIcon.classList.remove("imageRot");
      flashGreen();
    });
  };

  const fetchJourneyData = useCallback(() => {
    if (!docData) return;

    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => reject(new Error("Request timeout")), 6000);
    });

    Promise.race([
      fetch(
        `${process.env.REACT_APP_DBAPI_URL}/api/v1.0/getFormattedService?rid=${docData.rid}&sourceCRS=${docData.sourceCRS}&destCRS=${docData.destCRS}`
      ),
      timeoutPromise,
    ])
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setApiData(data);
        setLoadingAPI(false);
        seterrorLoadingAPI(false);
        setLastUpdated(new Date());
        setapiHasLoadedOnce(true);

        // This is the adjusted part.
        if (docData.timestamp) {
          const docTimestamp = docData.timestamp.toDate(); // Assuming timestamp is a Firebase Timestamp.
          const oneMinuteAgo = new Date();
          oneMinuteAgo.setMinutes(oneMinuteAgo.getMinutes() - 1080);
          if (docTimestamp < oneMinuteAgo) {
            setIsOutdated(true);
          } else if (data.isOutdated) {
            setIsOutdated(true);
          } else {
            setIsOutdated(false);
          }
        } else {
          if (data.isOutdated) {
            setIsOutdated(true);
          } else {
            setIsOutdated(false);
          }
        }
      })
      .catch((error) => {
        seterrorLoadingAPI(true);
        setLoadingAPI(false);
      });
  }, [docData]);

  function isDocumentVisible() {
    // Using the Page Visibility API
    return document.visibilityState === "visible";
  }

  const conditionalRefresh = useCallback(() => {
    if (isDocumentVisible()) {
      fetchJourneyData();
    }
  }, [fetchJourneyData]);

  useEffect(() => {
    function handleVisibilityChange() {
      conditionalRefresh();
    }

    document.addEventListener("visibilitychange", handleVisibilityChange);

    const intervalId = setInterval(conditionalRefresh, 5 * 60 * 1000);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearInterval(intervalId);
    };
  }, [conditionalRefresh]);

  useEffect(() => {
    fetchJourneyData();
  }, [docData, fetchJourneyData]);

  useEffect(() => {
    fetchJourneyData();
  }, [docData, fetchJourneyData]);

  useEffect(() => {
    if (docData) {
      setShareLink(
        `${
          process.env.REACT_APP_DB_BASE_URL
        }/onboardshare?rid=${encodeURIComponent(
          docData?.rid
        )}&sourceCRS=${encodeURIComponent(
          docData?.sourceCRS
        )}&destCRS=${encodeURIComponent(
          docData.destCRS
        )}&terminates=${encodeURIComponent(
          docData.terminates
        )}&trainOperator=${encodeURIComponent(
          docData.trainOperator
        )}&departureScheduled=${encodeURIComponent(docData.departureScheduled)}`
      );
    }
  }, [docData]);

  const handleHelpMenuClick = () => {
    setShowHelpHeader(true);
  };

  const handleShareJourneyMenuClick = () => {
    setShowShareJourney(true);
  };

  const handleSearchJourneyClick = () => {
    setSelfRefer(true);
    setShowSearchJourney(true);
  };

  if (loadingAPI || loadingDocument) return <Loading />;
  if (!currentUser || !userDocument || !userDocument.isPro)
    return <NotPro currentUser={currentUser} userDocument={userDocument} />;

  return (
    <>
      <OtherHeader url="onboard" name="Onboard" />
      <HelpHeader show={ShowHelpHeader} handleClose={handleCloseHelpHeader} />
      <ShareHeader
        show={ShowShareJourney}
        handleClose={handleCloseShareJourneyHeader}
        link={shareLink}
      />
      {apiHasLoadedOnce &&
      documentHasLoadedOnce &&
      !apiData.isDividing &&
      !apiData.isJoining ? (
        <OnboardHeader
          title="Onboard Hub"
          subtext={
            !ShowSearchJourney &&
            !noDocument &&
            !IsOutdated &&
            `${docData?.departureScheduled} ${docData?.trainOperator} service terminating at ${docData?.terminates}`
          }
          error={errorLoadingAPI}
          lastUpdated={lastUpdated}
          showSearch={ShowSearchJourney}
          noDocument={noDocument}
          isOutdated={IsOutdated}
        />
      ) : (
        <BlueHeader title="Onboard Hub" />
      )}

      {!ShowSearchJourney &&
      (!noDocument || !errorLoadingDocument) &&
      !IsOutdated && !noDocument ? (
        <>
          <div className="container mt-3">
            <div className="row justify-content-end">
              <div className="col-auto reduced-right-padding">
                <button
                  id="share-journey"
                  type="button"
                  aria-label="Share Journey"
                  className="btn btn-ontheme align-items-center btn-block"
                  onClick={handleShareJourneyMenuClick}
                >
                  <SendIcon fontSize="small" />
                </button>
              </div>
              <div className="col-auto reduced-left-padding reduced-right-padding">
                <button
                  id="clear-journey"
                  type="button"
                  aria-label="Search for new journey"
                  className="btn btn-ontheme align-items-center btn-block"
                  onClick={handleSearchJourneyClick}
                >
                  <SearchIcon fontSize="small" />
                </button>
              </div>
              <div className="col-auto reduced-left-padding reduced-right-padding">
                <button
                  id="help"
                  type="button"
                  aria-label="Help"
                  className="btn btn-ontheme align-items-center btn-block"
                  onClick={handleHelpMenuClick}
                >
                  <HelpIcon fontSize="small" />
                </button>
              </div>
              <div className="col-auto reduced-left-padding">
                <button
                  id="refresh-data"
                  aria-label="Refresh Journey"
                  type="button"
                  className="btn btn-ontheme align-items-center btn-block"
                  onClick={handleRefreshClick}
                >
                  <svg
                    id="rotateicon"
                    className="bi bi-arrow-clockwise"
                    width="1.3em"
                    height="1.3em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.17 6.706a5 5 0 017.103-3.16.5.5 0 10.454-.892A6 6 0 1013.455 5.5a.5.5 0 00-.91.417 5 5 0 11-9.375.789z"
                      clipRule="evenodd"
                    />
                    <path
                      fillRule="evenodd"
                      d="M8.147.146a.5.5 0 01.707 0l2.5 2.5a.5.5 0 010 .708l-2.5 2.5a.5.5 0 11-.707-.708L10.293 3 8.147.854a.5.5 0 010-.708z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <svg
                    id="tick-refresh-data"
                    className="bi bi-check d-none"
                    width="1.3em"
                    height="1.3em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M13.854 3.646a.5.5 0 010 .708l-7 7a.5.5 0 01-.708 0l-3.5-3.5a.5.5 0 11.708-.708L6.5 10.293l6.646-6.647a.5.5 0 01.708 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
            {(!errorLoadingAPI || apiHasLoadedOnce) &&
            (!errorLoadingDocument || documentHasLoadedOnce) ? (
              <>
                {!apiData.isDividing && !apiData.isJoining ? (
                  <div>
                    <div className="border-bottom mb-2 pt-4 pb-0">
                      <h5 className="mb-2">Journey Information</h5>
                    </div>
                    <div className="col-auto mb-2 pl-0">
                      {apiData.cancellationReason && apiData.isCancelled ? (
                        <span className="sts-red">
                          {apiData.cancellationReason}.
                        </span>
                      ) : apiData.delayReason !== "" ? (
                        <span className="sts-amber">
                          {apiData.delayReason}.
                        </span>
                      ) : apiData.serviceCancelledAtSourceStop &&
                        apiData.serviceCancelledAtDestStop ? (
                        <span className="sts-red">
                          This service is still running, but it is no longer
                          calling at your origin and destination stations.
                        </span>
                      ) : apiData.serviceCancelledAtSourceStop ? (
                        <span className="sts-red">
                          This service is still running, but it is no longer
                          calling at your origin station.
                        </span>
                      ) : apiData.serviceCancelledAtDestStop ? (
                        <span className="sts-red">
                          This service is still running, but it is no longer
                          calling at your destination station.
                        </span>
                      ) : (
                        <>
                          <span className="font-weight-bold">
                            Service Duration:{" "}
                          </span>{" "}
                          {apiData.duration}
                        </>
                      )}
                    </div>

                    <div className="border-bottom mb-2 pt-4 pb-0">
                      <h5 className="mb-2">Journey Progress</h5>
                    </div>
                    <div className="justify-content-left d-flex pb-2 pt-2 border-bottom">
                      <div className="text-center align-self-center icon-column d-flex flex-column align-items-center text-center mr-3">
                        <div className="font-weight-bold">Arr.</div>
                      </div>
                      <div className="text-center align-self-center icon-column d-flex flex-column align-items-center text-center mr-4">
                        <div className="font-weight-bold">Dep.</div>
                      </div>

                      <div className="col-pad-override col my-auto serviceinfo">
                        <div className="font-weight-bold">Station</div>
                      </div>
                      <div className="ml-auto text-center cp-plat justify-content-center align-content-center d-flex flex-column">
                        <div className="font-weight-bold">Plat.</div>
                      </div>
                    </div>
                    <div className="pb-5">
                      <TableRows apiData={apiData} />
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="text-center pb-2 pt-4">
                      Uh oh! It looks like this is a dividing or joining train!
                    </div>
                    <div className="col-auto text-center reduced-right-padding reduced-left-padding pt-3 mb-3">
                      We currently do not support services that divide in the
                      Onboard Hub. If you're a Pro member and regularly catch
                      this service please get in touch and let us know so we can
                      prioritise support!
                    </div>
                  </>
                )}
              </>
            ) : (
              <div className="container mt-3">
                <div className="col-12 text-center">
                  Uh oh. There was an error loading the Onboard Hub. This is
                  usually caused by a bad network connection or in some rarer
                  cases an issue with National Rail where we source our data.
                  Try hitting the refresh button above.
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <Search
          setShowSearchJourney={setShowSearchJourney}
          selfRefer={SelfRefer}
          noDocument={noDocument}
          isOutdated={IsOutdated}
        />
      )}
    </>
  );
};

export default Onboard;
