import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/Authcontext";
import { auth } from "../../Firebase"; // Make sure to update the import path if necessary
import { Link, useLocation } from "react-router-dom";
import DeviceUtils from "../utils/deviceType";

function Navbar() {
  const { currentUser } = useAuth();
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [referURL, setReferURL] = useState(null);

  const handleLogout = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  useEffect(() => {
    setIsCollapsed(true);
  }, [location]);

  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    setIsCollapsed(true);
  }, [location]);

  useEffect(() => {
    setReferURL("/login?referURL=" + encodeURIComponent(window.location.href));
  }, []);

  return (
    <>
      <div className="navbar navbar-expand-lg navbar-dark bg-primary">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img
              className="logo-img"
              src="/img/db_horizontal_logo.png"
              alt="departureboard.io Logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNavbar}
            aria-controls="navbarResponsive"
            aria-expanded={!isCollapsed}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isCollapsed ? "" : "show"}`}
            id="navbarResponsive"
          >
            <ul className="nav navbar-nav ml-auto navbar-custom">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Home
                </a>
              </li>
              <>
                <li className="nav-item">
                  <a className="nav-link" href="/pro">
                    Pro
                  </a>
                </li>
              </>
              {currentUser ? (
                <>
                  <li className="nav-item">
                    <a className="nav-link" href="/favourites">
                      Favourites
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/onboard">
                      Onboard
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/profile">
                      Profile
                    </a>
                  </li>
                  {!DeviceUtils.isIOSStandalonePWA() && (
                    <li className="nav-item">
                      <button
                        className="nav-link btn btn-link"
                        onClick={handleLogout}
                      >
                        Logout
                      </button>
                    </li>
                  )}
                </>
              ) : (
                <>
                  <li className="nav-item">
                    <a className="nav-link" href={referURL}>
                      Login / Register
                    </a>
                  </li>
                </>
              )}
              <li className="nav-item">
                <a className="nav-link" href="/help">
                  Help
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
