// renderPlatformInfo.js

export const renderPlatformInfo = (journey, userDocument) => {
  if (journey.isCancelled) {
    return "N/A";
  }
  if (!userDocument && journey.platformHidden) {
    return "Pend";
  }
  if (userDocument) {
    if (journey.platformHidden && !userDocument.isPro) {
      return "Pend";
    }
  }
  if (journey.departingPlatform === "Pending") {
    return "Pend";
  }
  return journey.departingPlatform;
};

export const renderCallingPointPlatform = (cp) => {
  if (cp.isCancelled) {
    return "N/A";
  }
  if (cp.platform === "Pending" || cp.platform === "") {
    return "Pend";
  }
  return cp.platform;
};

export const renderPlatformTitle = (journey, userDocument) => {
  if (
    journey.platformHidden &&
    !journey.isCancelled &&
    journey.departingPlatform !== "Pending" &&
    journey.departingPlatform !== "" &&
    (userDocument && userDocument.isPro)
  ) {
    return "Est Plat.";
  }

  return "Plat.";
};

export const renderCallingPointPlatformTitle = (cp) => {
  if (
    cp.platformHidden &&
    !cp.isCancelled &&
    cp.platform !== "Pending" &&
    cp.platform !== ""
  ) {
    return "Est Plat.";
  }
  return "Plat.";
};
