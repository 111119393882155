// DeviceUtils.js
import React from "react";

class DeviceUtils extends React.Component {
  static isIOS() {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    return isIOS;
  }

  static isAndroid() {
    const isAndroid = /Android/.test(navigator.userAgent);

    return isAndroid;
  }

  static isIOSStandalonePWA() {
    const isIOSStandalonePWA =
      DeviceUtils.isIOS() && window.navigator.standalone === true;

    return isIOSStandalonePWA;
  }

  static isHomescreenLaunch() {
    return this.isIOSStandalonePWA() && this.isAndroidLaunchedFromHomescreen();
  }

  static isAndroidLaunchedFromHomescreen() {
    const isFromHomescreen =
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone;

    const isAndroidfromHomescreen = DeviceUtils.isAndroid() && isFromHomescreen;

    return isAndroidfromHomescreen;
  }

  render() {
    return null;
  }
}

export default DeviceUtils;
