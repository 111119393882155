import React from "react";
import { useAuth } from "../../contexts/Authcontext";

const ExpandUnlockWithPro = ({ expanded }) => {
  let myURL = "/pro?referURL=" + encodeURIComponent(window.location.href);
  let loginURL = "/login?referURL=" + encodeURIComponent(window.location.href);

  const { currentUser } = useAuth();

  return (
    <div className="expand-service">
      <div className="pt-3 pl-3 pr-3">
        <div className="border-bottom mb-2 pb-0">
          <h5 className="mb-1">Calling Points</h5>
        </div>
        <div className="mr-4 ml-4">
          <p className="text-center pt-1">
            Uh oh! You need to be subscribed to departureboard.io Pro to see
            this section.
          </p>
          <p className="text-center pt-0">
            <a href={myURL}>Learn more and Subscribe</a>
          </p>
          {!currentUser && (
            <p className="text-center pt-1">
              Already Pro? <a href={loginURL}>Click here</a> to login.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExpandUnlockWithPro;
