function ArrivalDueOnBoard({ cp, detectedCancelType }) {
  const isCancelled =
    cp.isCancelled || detectedCancelType === "ENTIRE_SERVICE_CANCELLED";

  if (isCancelled) {
    return <span className="depart-due sts-red">Cancelled</span>;
  }

  // If the train has arrived, set the details
  if (cp.hasArrived) {
    if (cp.actualArrival === "On time" || cp.arrivedEarly) {
      return <span className="depart-due sts-green">{cp.actualArrival}</span>;
    } else if (cp.actualArrival === "Delayed") {
      return <span className="depart-due sts-amber">Delayed</span>;
    } else {
      return <span className="depart-due sts-amber">{cp.actualArrival}</span>;
    }
  }

  // If the train has arrived, set the details
  if (!cp.hasArrived) {
    if (cp.estimatedArrival === "On time" || cp.arrivalDueEarly) {
      return (
        <span className="depart-due sts-green">{cp.estimatedArrival}</span>
      );
    } else if (cp.estimatedArrival === "Delayed" || cp.estimatedArrival === "N/A") {
      return (
        <span className="depart-due sts-amber">Delayed</span>
      );
    } else {
      return <span className="depart-due sts-amber">{cp.estimatedArrival}</span>;
    }
  }

  return <span className="depart-due sts-amber">Unknown</span>;
}

export default ArrivalDueOnBoard;
