import React, { useState, useEffect } from "react";
import "./App.css";
import "./components/assets/css/bootstrap.min.css";
import "./components/assets/css/datatables.min.css";
import "./components/assets/css/custom.css";
import "./components/assets/css/global-custom-css.css";

import "bootstrap/dist/js/bootstrap.bundle.min";
import NavBar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import { useAuth } from "./contexts/Authcontext";
import Help from "./Help";
import ProBanner from "./components/common/Pro";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Journey from "./Journey";
import Home from "./Home";
import Login from "./Login";
import Profile from "./Profile";
import Deviated from "./components/common/Deviated";
import Pro from "./Pro";
import Onboard from "./Onboard";
import OnboardShare from "./OnboardShare";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms from "./Terms";

import { AuthProvider } from "./contexts/Authcontext";
import DeviceUtils from "./components/utils/deviceType";
import Favourites from "./Favourites";
import NotFound from "./NotFound";

import ReactGA from "react-ga4"; // import ReactGA

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID); // initialize Google Analytics with your tracking ID

function Main() {
  const [showProBanner, setShowProBanner] = useState(false);

  const location = useLocation();
  const [initialPage, setInitialPage] = useState(null);
  const [showDeviatedBanner, setShowDeviatedBanner] = useState(false);

  useEffect(() => {
    if (DeviceUtils.isIOSStandalonePWA()) {
      const storedInitialPage = localStorage.getItem("initialPage");

      if (!storedInitialPage) {
        localStorage.setItem("initialPage", location.pathname);
        setInitialPage(location.pathname);
      } else {
        setInitialPage(storedInitialPage);
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (DeviceUtils.isIOSStandalonePWA() && initialPage) {
      if (initialPage !== location.pathname) {
        setShowDeviatedBanner(true);
      } else {
        setShowDeviatedBanner(false);
      }
    }
  }, [initialPage, location.pathname]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (DeviceUtils.isIOSStandalonePWA() && initialPage) {
        if (initialPage !== location.pathname) {
          setShowDeviatedBanner(true);
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [initialPage, location.pathname]);

  // update Google Analytics with the new page view every time the route changes
  useEffect(() => {
    setTimeout(() => {
      ReactGA.event("page_view", {
        page_title: window.location.origin + location.pathname, // Specify the page title
        page_location: window.location.origin + location.pathname, // Specify the page location without query parameters
      });
      if (DeviceUtils.isIOSStandalonePWA()) {
        ReactGA.event("ios_homescreen_launch", {
          page_title: window.location.origin + location.pathname, // Specify the page title
          page_location: window.location.origin + location.pathname, // Specify the page location without query parameters
        });
      }
      if (DeviceUtils.isAndroidLaunchedFromHomescreen()) {
        ReactGA.event("android_homescreen_launch", {
          page_title: window.location.origin + location.pathname, // Specify the page title
          page_location: window.location.origin + location.pathname, // Specify the page location without query parameters
        });
      }
    }, 0);
  }, [location]);

  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get("isPro") === "true") {
      setShowProBanner(true);
      query.delete("isPro");
      navigate(`${location.pathname}?${query.toString()}`, { replace: true });
    }
  }, [location, navigate]);

  return (
    <>
      <NavBar />
      {showDeviatedBanner && <Deviated initialPage={initialPage} />}
      {showProBanner && <ProBanner />}{" "}
      {/* Replace with your banner component */}
      <div className="main-container">
        <div className="content-main">
          <Routes>
            <Route path="/journey/:origin/:destination" element={<Journey />} />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Home />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/pro" element={<Pro />} />
            <Route path="/favourites" element={<Favourites />} />
            <Route path="/onboard" element={<Onboard />} />
            <Route path="/onboardshare" element={<OnboardShare />} />
            <Route path="/help" element={<Help />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
}

function App() {
  const { loading } = useAuth();
  return (
    <Router>
      <div className="App">
        <AuthProvider>{!loading && <Main />}</AuthProvider>
      </div>
    </Router>
  );
}

export default App;
