import React from "react";

function Howweredifferent() {
  return (
    <div className="featured-bg gray">
      <div className="container">
        <h2 className="black pb-3 pt-2">What's different?</h2>
        <div className="title-left">We are really fast</div>
        <p className="subbb black">
          Commuters often arrive to the station with just seconds until their
          train is due to depart. We focus on providing information sub-second,
          making sure that a lack of information isn't the reason you miss your
          train.
        </p>
        <div className="title-left">An icon for every route</div>
        <p className="subbb black">
          Unlike most train apps, we're web based. We allow you to add each of
          your journeys to your homescreen so you can get the information you
          need as quickly as possible. Replace ten taps with just one.
        </p>
        <div className="title-left">Trusted with over 1 million journeys</div>
        <p className="subbb black">
          We've powered over 1 million journeys since launch. Once you know how
          to use departureboard.io you'll never go back.
        </p>
      </div>
    </div>
  );
}

export default Howweredifferent;
