import React from "react";
import StationHeader from "./Stationheader";
import TimeSinceUpdate from "./micro/LastUpdated";

const Journeyheader = ({
  sourceCRS,
  sourceStationName,
  destCRS,
  destStationName,
  os,
  lastUpdated,
  error,
}) => {
  return (
    <div>
      <div className="block-color-header-blue journey-result-header">
        <StationHeader
          sourceCRS={sourceCRS}
          destCRS={destCRS}
          sourceStationName={sourceStationName}
          destStationName={destStationName}
          os={os}
        />

        <div className="block-color-header-small pb-3">
          {!error && (
            <div>
              Last updated <TimeSinceUpdate lastUpdated={lastUpdated} />
            </div>
          )}{" "}
          {error && (
            <div className="text-center">
              ❗ Refresh failed, please check your network<br/> (last updated{" "}
              <TimeSinceUpdate lastUpdated={lastUpdated} />)
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Journeyheader;
