import React, { useState } from "react";
import { Modal, Button, InputGroup, FormControl } from "react-bootstrap";

function ShareHeader({ show, handleClose, link }) {
  const [copySuccess, setCopySuccess] = useState("");

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess("Link copied to clipboard!");
    } catch (err) {
      setCopySuccess("Failed to copy text");
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      id="shareJourney"
      aria-labelledby="shareJourney"
      aria-hidden="true"
    >
      <Modal.Header>
        <Modal.Title>Share Journey</Modal.Title>
        <Button
          variant="none"
          className="close"
          onClick={handleClose}
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-add-body pt-1">
          <div className="pb-0">
            You can share this page with family or friends to help them follow
            your journey as it progresses. Just send them the link below.
            <InputGroup className="mb-3 pt-4">
              <FormControl value={link} readOnly />
              <Button
                variant="outline-secondary ml-2"
                onClick={() => copyToClipBoard(link)}
              >
                Copy{" "}
              </Button>
            </InputGroup>
            {copySuccess}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ShareHeader;
