// utils.js

export const setElementWidth = (className) => {
    const elements = document.querySelectorAll(className);
    let maxWidth = 0;
  
    elements.forEach((element) => {
      const width = element.getBoundingClientRect().width;
      maxWidth = Math.max(maxWidth, width);
    });
  
    elements.forEach((element) => {
      element.style.width = `${maxWidth}px`;
    });
  };
  