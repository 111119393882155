import React from "react";

function OneIcon() {
  return (
    <div className="featured-bg white border-top border-bottom">
      <div className="container">
        <div className="row">
          <div className="align-self-center col-md-12 col-lg-6">
            <h2>An icon for every journey</h2>
            <p style={{ paddingTop: "0.4rem" }}>
              Providing quick train departure information right from your homescreen.
            </p>
            <p className="d-none d-md-block" >
              Turn ten taps to one, and stop missing trains.
            </p>
          </div>
          <div className="col-md-12 col-lg-6">
            <img
              width="300rem"
              alt="gif showing how site works"
              src="/img/overview.webp"
            />
          </div>

          <div className="col-md-12 d-block d-md-none pt-3 pb-1">
            Turn ten taps to one, and stop missing trains.
          </div>
        </div>
      </div>
    </div>
  );
}

export default OneIcon;
