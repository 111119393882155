import React from "react";

function ProBanner() {
  const handleClose = () => {
    localStorage.setItem("bannerClosed", "true");
    document.querySelector(".pro-header").style.display = "none";
  };

  return (
    <>
      <div className="block-color-header-blue pro-header">
        <div className="col d-flex flex-column justify-content-between">
          <div className="pt-2 pb-2 col-11">
            <div>
              🎉 You're now a PRO member. You've got instant access to all Pro
              features! 🎉
            </div>
            <div className="pt-3">
              If you're using an iPhone, you'll need to login on each icon you
              have already saved to your homescreen. All new icons going forward
              will be automatically logged in.
            </div>
            <div className="pt-3">
              Issues, questions, comments? Just email{" "}
              <a href="mailto:support@departureboard.io">
                support@departureboard.io
              </a>
            </div>
          </div>
          <div>
            <button className="close-btn white" onClick={handleClose}>
              &times;
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProBanner;
