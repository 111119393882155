import React, { useState, useEffect } from "react";
import Departuredue from "./micro/Departuredue";
import IsQuickest from "./micro/isQuickest";
import Arrivaldue from "./micro/Arrivaldue";
import { useAuth } from "../../contexts/Authcontext";
import ExpandService from "./ExpandService";
import ExpandUnlockWithPro from "./ExpandUnlockWithPro";
import { setElementWidth } from "../utils/width";
import {
  renderPlatformInfo,
  renderPlatformTitle,
} from "../utils/platformUtils";
import ArrowDownIcon from "./micro/Arrow";
import ReactGA from "react-ga4"; // import ReactGA
import { useLocation } from "react-router-dom";

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID); // initialize Google Analytics with your tracking ID

const JourneyTable = ({ journeys }) => {
  const { userDocument } = useAuth();
  const [expandedRow, setExpandedRow] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setElementWidth(".departureinfo");
      setElementWidth(".platforminfo");
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [journeys]);

  const toggleExpandedRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
    ReactGA.event("expand_journey_row_click", {
      page_title: window.location.origin + location.pathname, // Specify the page title
      page_location: window.location.origin + location.pathname, // Specify the page location without query parameters
    });
  };

  const renderExpandedComponent = (journey) => {
    if (!userDocument || !userDocument.isPro) {
      return <ExpandUnlockWithPro expanded={expandedRow} />;
    }

    return (
      <ExpandService
        expanded={expandedRow}
        journey={journey}
        userDocument={userDocument}
      />
    );
  };

  return (
    <div className="mb-5">
      <div
        id="journeyresults_wrapper"
        className="dataTables_wrapper dt-bootstrap4 no-footer"
      >
        <div className="row">
          <div className="col-sm-12">
            <table
              className="table dataTable no-footer"
              id="journeyresults"
              role="grid"
            >
              <tbody>
                {journeys.map((journey, index) => (
                  <React.Fragment key={journey.rid}>
                    <tr
                      role="row"
                      className="odd"
                      onClick={() => toggleExpandedRow(index)}
                    >
                      <td className="resultrow">
                        <div className="train-service">
                          <div className="row h-100" style={{ margin: "auto" }}>
                            <div className="col-pad-override my-auto departureinfo">
                              <div className="depart-time">
                                {journey.departureScheduled}
                              </div>
                              <Departuredue journey={journey} />
                              <div className="text-center arrow-class my-auto">
                                <ArrowDownIcon flip={expandedRow === index} />
                              </div>
                            </div>
                            <div className="col-pad-override col my-auto serviceinfo">
                              <div className="train-final-dest">
                                Train to {journey.trainTerminates}{" "}
                                <IsQuickest journey={journey} />
                              </div>
                              <div className="train-user-journey-dest">
                                {!journey.isCancelled && (
                                  <>
                                    {journey.trainTerminates ===
                                    journey.destFullName ? (
                                      <>
                                        Arrving at {journey.destFullName} at:{" "}
                                      </>
                                    ) : (
                                      <>
                                        Calling at {journey.destFullName} at:{" "}
                                      </>
                                    )}
                                  </>
                                )}
                                <Arrivaldue journey={journey} />{" "}
                              </div>
                              <div className="train-duration">
                                Duration: {journey.duration}
                              </div>
                            </div>
                            <div className="col-pad-override h-100 col-auto my-auto platforminfo">
                              <div className="plat-title">
                                {renderPlatformTitle(journey, userDocument)}
                              </div>
                              <div className="plat-number">
                                {renderPlatformInfo(journey, userDocument)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    {expandedRow === index && (
                      <tr>
                        <td colSpan="100%">
                          {renderExpandedComponent(journey)}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JourneyTable;
