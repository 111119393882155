import { useContext, useState, useEffect } from "react";
import { JourneyDataContext } from "../../contexts/Journeydatacontext";
import { Modal, Button } from "react-bootstrap";
import DeviceUtils from "../utils/deviceType";
import { useAuth } from "../../contexts/Authcontext";
import GoogleAd from "../common/Ads";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import ReactGA from "react-ga4"; // import ReactGA
import { useLocation } from "react-router-dom";

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID); // initialize Google Analytics with your tracking ID

function Buttonbar({ sourceCRS, destCRS, sourceName, destName }) {
  const [showAddtoHomescreen, setShowAddtoHomescreen] = useState(false);
  const [showSubscribetoPro, setShowSubscribetoPro] = useState(false);
  const location = useLocation();

  const handleAddToHomeScreenClick = () => {
    setShowAddtoHomescreen(true);
    ReactGA.event("add_to_homescreen_button_click", {
      page_title: window.location.origin + location.pathname, // Specify the page title
      page_location: window.location.origin + location.pathname, // Specify the page location without query parameters
    });
  };

  const { userDocument, currentUser } = useAuth(); // Use the hook to get currentUser and userDocument

  const [isFav, setIsFav] = useState(false);
  const [isFavLoaded, setIsFavLoaded] = useState(false);

  // createFavouriteDocument creates a favourited journey
  const createFavouriteDocument = async () => {
    const firestore = getFirestore();
    const docRef = doc(
      firestore,
      "Users",
      currentUser.uid,
      "favouriteJourneys",
      sourceCRS + destCRS
    );
    const favData = {
      sourceCRS: sourceCRS,
      destCRS: destCRS,
      sourceName: sourceName,
      destName: destName,
    };
    await setDoc(docRef, favData);
    setIsFav(true);
    ReactGA.event("favourite_journey_button_click", {
      page_title: window.location.origin + location.pathname, // Specify the page title
      page_location: window.location.origin + location.pathname, // Specify the page location without query parameters
    });
  };

  // deleteFavouriteDocument deletes a favourited journey
  const deleteFavouriteDocument = async () => {
    const firestore = getFirestore();
    const docRef = doc(
      firestore,
      "Users",
      currentUser.uid,
      "favouriteJourneys",
      sourceCRS + destCRS
    );
    await deleteDoc(docRef);
    setIsFav(false);
    ReactGA.event("unfavourite_journey_button_click", {
      page_title: window.location.origin + location.pathname, // Specify the page title
      page_location: window.location.origin + location.pathname, // Specify the page location without query parameters
    });
  };

  useEffect(() => {
    if (currentUser) {
      const checkJourneyFavourited = async () => {
        const firestore = getFirestore();
        const docRef = doc(
          firestore,
          "Users",
          currentUser.uid,
          "favouriteJourneys",
          sourceCRS + destCRS
        );
        const fav = await getDoc(docRef);
        if (fav.exists()) {
          setIsFav(true);
        } else {
          setIsFav(false);
        }
        setIsFavLoaded(true);
      };

      checkJourneyFavourited();
    }
  }, [currentUser, sourceCRS, destCRS]);

  const { fetchJourneyData, allowRefresh, setAllowRefresh } =
    useContext(JourneyDataContext);

  const handleJourneyinReverse = (e) => {
    ReactGA.event("journey_in_reverse_button_click", {
      page_title: window.location.origin + location.pathname, // Specify the page title
      page_location: window.location.origin + location.pathname, // Specify the page location without query parameters
    });
    e.preventDefault();
    window.location.href = `/journey/${destCRS}/${sourceCRS}`;
  };

  const handleClose = () => {
    setShowAddtoHomescreen(false);
  };

  const handleProClose = () => {
    setShowSubscribetoPro(false);
  };

  const flashGreen = () => {
    const refreshData = document.getElementById("refresh-data");
    const tickRefreshData = document.getElementById("tick-refresh-data");
    const rotateIcon = document.getElementById("rotateicon");

    refreshData.classList.add("bg-green");
    tickRefreshData.classList.remove("d-none");
    rotateIcon.classList.add("d-none");

    setTimeout(() => {
      setAllowRefresh(true);
    }, 5000);

    setTimeout(() => {
      refreshData.classList.remove("bg-green");
      tickRefreshData.classList.add("d-none");
      rotateIcon.classList.remove("d-none");
    }, 1000);
  };

  const handleRefreshClick = () => {
    if (allowRefresh) {
      setAllowRefresh(false);
      fetchJourneyData();
    }

    const rotateIcon = document.getElementById("rotateicon");
    rotateIcon.classList.add("imageRot");
    rotateIcon.addEventListener("animationend", () => {
      rotateIcon.classList.remove("imageRot");
      flashGreen();
    });

    ReactGA.event("refresh_journey_click", {
      page_title: window.location.origin + location.pathname, // Specify the page title
      page_location: window.location.origin + location.pathname, // Specify the page location without query parameters
      permitted: allowRefresh,
    });
  };

  return (
    <>
      {(!userDocument || (userDocument && userDocument.isPro === false)) && (
        <>
          <GoogleAd />
          <div className="text-center small pb-3 pt-0 bg-grey">
            Support our running costs, get Predicted Platforms, Calling Points, the Onboard Hub
            and more with <a href="/pro"> departureboard.io Pro</a>
          </div>
        </>
      )}
      <div className="row justify-content-end">
        <Modal
          show={showAddtoHomescreen}
          onHide={handleClose}
          id="addtoHomescreen"
          aria-labelledby="addtoHomescreen"
          aria-hidden="true"
        >
          <Modal.Header>
            <Modal.Title>Add to Home Screen</Modal.Title>
            <Button
              variant="none"
              className="close"
              onClick={handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="row justify-content-center">
              <div className="col-auto">
                <img
                  className="modal-icon"
                  src="/img/db-rounded-logo.png"
                  alt="departureboard.io"
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto homescreensimulationtext">
                {sourceCRS} to {destCRS}
              </div>
            </div>

            <div className="modal-add-body">
              departureboard.io works best when you add your journey to your
              Home Screen. You'll be able to view the departures for your
              journey in less than a second.
            </div>
          </Modal.Body>
          {DeviceUtils.isIOS() && (
            <Modal.Footer>
              <span className="instruction-header">Instructions</span>
              <br />
              1. Click{" "}
              <img
                className="ios-share-icon"
                src="/img/ios-share-icon.png"
                alt="iOS Share Icon"
              />{" "}
              in the Safari menu below
              <br />
              2. Click "Add to Home Screen"
            </Modal.Footer>
          )}
          {DeviceUtils.isAndroid() && (
            <Modal.Footer>
              <span className="instruction-header">Instructions</span>
              <br />
              <img
                className="android-share-icon"
                src="/img/android-share-icon.png"
                alt="Android Share Icon"
              />
              <br />
              1. Click the Settings icon in the top right of your browser
              <br />
              2. Click "Add to Home Screen"
            </Modal.Footer>
          )}
        </Modal>
        {/* Subscribe to Pro Modal */}
        <Modal
          show={showSubscribetoPro}
          onHide={handleProClose}
          id="subscribeToPro"
          aria-labelledby="subscribeToPro"
          aria-hidden="true"
        >
          <Modal.Header>
            <Modal.Title>Subscribe to Pro</Modal.Title>
            <Button
              variant="none"
              className="close"
              onClick={handleProClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-add-body pt-0">
              You need to subscribe to departureboard.io Pro to be able to
              favourite journeys.
            </div>
            <div className="modal-add-body pt-3 pb-1">
              <a href="/pro">Learn more and Subscribe</a>
            </div>
          </Modal.Body>
        </Modal>
        <div className="col-auto reduced-right-padding">
          {((DeviceUtils.isIOS() && !DeviceUtils.isIOSStandalonePWA()) ||
            (DeviceUtils.isAndroid() &&
              !DeviceUtils.isAndroidLaunchedFromHomescreen())) && (
            <button
              id="add-to-homescreen"
              data-toggle="modal"
              data-target="#addtoHomescreen"
              type="button"
              className="btn btn-ontheme align-items-center btn-block"
              onClick={handleAddToHomeScreenClick}
            >
              Add to Home Screen
            </button>
          )}
        </div>

        {((!isFav && isFavLoaded) || !userDocument || !userDocument.isPro) && (
          <div className="col-auto reduced-right-padding reduced-left-padding">
            <button
              id="favouriteJourney"
              aria-label="Favourite Journey"
              type="button"
              className="btn btn-ontheme align-items-center btn-block"
              onClick={
                !userDocument || !userDocument.isPro
                  ? setShowSubscribetoPro
                  : createFavouriteDocument
              }
            >
              <svg
                id="staricon"
                className="star-icon"
                width="1.2em"
                height="1.2em"
                viewBox="0 0 512 512"
                fill="#FFFFFF"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M511.177,197.119c-1.975-6.079-7.23-10.51-13.554-11.429l-156.54-22.758L271.059,21.066
        c-2.829-5.731-8.667-9.359-15.059-9.359c-6.391,0-12.23,3.628-15.059,9.359l-70.024,141.866L14.377,185.689
        c-6.324,0.919-11.58,5.35-13.554,11.429c-1.976,6.079-0.328,12.753,4.25,17.214L118.338,324.74L91.619,480.664
        c-1.08,6.3,1.51,12.665,6.681,16.422c5.17,3.756,12.024,4.252,17.683,1.279l140.016-73.593l140.014,73.593
        c5.688,2.99,12.54,2.457,17.683-1.279c5.171-3.756,7.762-10.123,6.681-16.422L393.66,324.741l113.267-110.409
        C511.505,209.87,513.153,203.196,511.177,197.119z M363.897,306.851c-3.958,3.857-5.763,9.414-4.83,14.861l22.463,131.097
        l-117.718-61.875c-4.89-2.572-10.735-2.573-15.625,0l-117.719,61.875l22.463-131.097c0.934-5.446-0.872-11.004-4.83-14.861
        L52.878,214.03l131.607-19.133c5.469-0.795,10.195-4.23,12.643-9.185L256,66.439l58.872,119.274
        c2.447,4.955,7.174,8.39,12.643,9.185l131.607,19.133L363.897,306.851z"
                />
              </svg>
            </button>
          </div>
        )}
        {isFav && isFavLoaded && userDocument && userDocument.isPro && (
          <div className="col-auto reduced-right-padding reduced-left-padding">
            <button
              id="unfavouriteJourney"
              aria-label="Unfavourite Journey"
              type="button"
              className="btn btn-ontheme align-items-center btn-block"
              onClick={deleteFavouriteDocument}
            >
              <svg
                id="staricon"
                className="star-icon"
                width="1.2em"
                height="1.2em"
                viewBox="0 0 512 512"
                fill="#FFFFFF"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M 511.177 197.119 C 509.202 191.04 503.947 186.609 497.623 185.69 L 341.083 162.932 L 271.059 21.066 C 268.23 15.335 262.392 11.707 256 11.707 C 249.609 11.707 243.77 15.335 240.941 21.066 L 170.917 162.932 L 14.377 185.689 C 8.053 186.608 2.797 191.039 0.823 197.118 C -1.153 203.197 0.495 209.871 5.073 214.332 L 118.338 324.74 L 91.619 480.664 C 90.539 486.964 93.129 493.329 98.3 497.086 C 103.47 500.842 110.324 501.338 115.983 498.365 L 255.999 424.772 L 396.013 498.365 C 401.701 501.355 408.553 500.822 413.696 497.086 C 418.867 493.33 421.458 486.963 420.377 480.664 L 393.66 324.741 L 506.927 214.332 C 511.505 209.87 513.153 203.196 511.177 197.119 Z M 363.897 306.851 C 359.939 310.708 183.559 191.427 184.492 196.874 L 381.53 452.809 C 147.902 48.905 263.812 390.934 263.812 390.934 C 258.922 388.362 253.077 388.361 248.187 390.934 L 130.468 452.809 L 323.615 400.24 C 324.549 394.794 257.743 183.402 253.785 179.545 L 52.878 214.03 L 184.485 194.897 C 189.954 194.102 384.453 335.48 386.901 330.525 L 256 66.439 L 314.872 185.713 C 317.319 190.668 322.046 194.103 327.515 194.898 L 459.122 214.031 C 459.122 214.031 10.667 86.999 363.897 306.851 Z"
                  style={{ fill: "rgb(255, 247, 27)", fillRule: "nonzero" }}
                />
              </svg>
            </button>
          </div>
        )}
        <div className="col-auto reduced-right-padding reduced-left-padding">
          <button
            id="trains-in-reverse"
            aria-label="Show trains in opposite direction"
            type="button"
            className="btn btn-ontheme align-items-center btn-block"
            onClick={handleJourneyinReverse}
          >
            <svg
              className="bi bi-arrow-left-right"
              width="1.2em"
              height="1.2em"
              viewBox="0 0 16 16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10.146 7.646a.5.5 0 01.708 0l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L12.793 11l-2.647-2.646a.5.5 0 010-.708z"
                clipRule="evenodd"
              />
              <path
                fillRule="evenodd"
                d="M2 11a.5.5 0 01.5-.5H13a.5.5 0 010 1H2.5A.5.5 0 012 11zm3.854-9.354a.5.5 0 010 .708L3.207 5l2.647 2.646a.5.5 0 11-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 01.708 0z"
                clipRule="evenodd"
              />
              <path
                fillRule="evenodd"
                d="M2.5 5a.5.5 0 01.5-.5h10.5a.5.5 0 010 1H3a.5.5 0 01-.5-.5z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div className="col-auto reduced-left-padding">
          <button
            id="refresh-data"
            type="button"
            aria-label="Refresh data"
            className="btn btn-ontheme align-items-center btn-block"
            onClick={handleRefreshClick}
          >
            <svg
              id="rotateicon"
              className="bi bi-arrow-clockwise"
              width="1.3em"
              height="1.3em"
              viewBox="0 0 16 16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3.17 6.706a5 5 0 017.103-3.16.5.5 0 10.454-.892A6 6 0 1013.455 5.5a.5.5 0 00-.91.417 5 5 0 11-9.375.789z"
                clipRule="evenodd"
              />
              <path
                fillRule="evenodd"
                d="M8.147.146a.5.5 0 01.707 0l2.5 2.5a.5.5 0 010 .708l-2.5 2.5a.5.5 0 11-.707-.708L10.293 3 8.147.854a.5.5 0 010-.708z"
                clipRule="evenodd"
              />
            </svg>
            <svg
              id="tick-refresh-data"
              className="bi bi-check d-none"
              width="1.3em"
              height="1.3em"
              viewBox="0 0 16 16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M13.854 3.646a.5.5 0 010 .708l-7 7a.5.5 0 01-.708 0l-3.5-3.5a.5.5 0 11.708-.708L6.5 10.293l6.646-6.647a.5.5 0 01.708 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
}

export default Buttonbar;
