import Commonheader from "./components/headers/Common";
import BlueHeader from "./components/common/BlueHeader";

const Terms = () => {
  return (
    <>
      <BlueHeader title="Terms of Service" />
      <div className="container pb-5 pl-1 pr-1">
        <Commonheader page="Terms of Service" />
        <div className="row pt-4 justify-content-center">
          <div className="-12 text-center">
            Please see the <a href="/misc/terms.pdf">Terms of Service</a> here.
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
