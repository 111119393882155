function DepartureDueOnBoard({ cp, detectedCancelType }) {
  const isCancelled =
    cp.isCancelled || detectedCancelType === "ENTIRE_SERVICE_CANCELLED";

  if (isCancelled) {
    return <span className="depart-due sts-red">Cancelled</span>;
  }

  // If the train has arrived, set the details
  if (cp.hasDeparted) {
    if (cp.actualDeparture === "On time" || cp.departedEarly) {
      return <span className="depart-due sts-green">{cp.actualDeparture}</span>;
    } else if (cp.actualDeparture === "Delayed" || cp.actualDeparture === "N/A" ) {
      return <span className="depart-due sts-amber">Delayed</span>;
    } else {
      return <span className="depart-due sts-amber">{cp.actualDeparture}</span>;
    }
  }

  // If the train has arrived, set the details
  if (!cp.hasDeparted) {
    if (cp.estimatedDeparture === "On time" || cp.departureDueEarly) {
      return (
        <span className="depart-due sts-green">{cp.estimatedDeparture}</span>
      );
    } else if (cp.estimatedDeparture === "Delayed" || cp.estimatedDeparture === "N/A") {
      return (
        <span className="depart-due sts-amber">Delayed</span>
      );
    } else {
      return (
        <span className="depart-due sts-amber">{cp.estimatedDeparture}</span>
      );
    }
  
  }

  return <span className="depart-due sts-amber">Unknown</span>;
}

export default DepartureDueOnBoard;
