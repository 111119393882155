import React from "react";

const StationHeader = ({
  sourceCRS,
  sourceStationName,
  destCRS,
  destStationName,
  os,
}) => {
  const isIosOrUnknown = !os || os === "ios" || os === "unknown";
  const isAndroid = os === "android";

  return (
    <div className="container">
      <div className="d-none d-sm-block block-color-header-heading">
        {/* Full Station Name */}
        {sourceStationName}{" "}
        {isIosOrUnknown && <span id="ios-arr-big">&rarr;</span>}
        {isAndroid && <span id="android-arr-big">to</span>} {destStationName}
      </div>
      <div className="block-color-header-heading d-block d-sm-none">
        {/* Short Station Name */}
        {sourceCRS} {isIosOrUnknown && <span id="ios-arr-small">&rarr;</span>}
        {isAndroid && <span id="android-arr-small">to</span>} {destCRS}
      </div>
      <div className="block-color-header-small-mob d-block d-sm-none">
        {/* Full Station Name */}
        {sourceStationName} to {destStationName}
      </div>
    </div>
  );
};

export default StationHeader;
