import React from "react";

function ArrivalDue({ journey }) {
  const content = () => {
    if (journey.isCancelled) {
      if (journey.detectedCancelType === "CANCELLED_AT_USER_STOP") {
        return <span>This service is no longer stopping at your destination.</span>;
      } else {
        return <span>This train service is cancelled.</span>;
      }
    } else if (journey.arrivalDue === "Unknown" || journey.arrivalDue === "Delayed") {
      return <span className="orange-class">Unknown Delay</span>;
    } else if (journey.arrivalDue !== "On time") {
      return <span className="orange-class">{journey.arrivalDue}</span>;
    } else {
      return <span className="green-class">{journey.arrivalScheduled}</span>;
    }
  };

  return <>{content()}</>;
}

export default ArrivalDue;
