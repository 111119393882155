import Commonheader from "./components/headers/Common";
import BlueHeader from "./components/common/BlueHeader";

const PrivacyPolicy = () => {
  return (
    <>
      <BlueHeader title="Privacy Policy" />
      <div className="container pb-5 pl-1 pr-1">
        <Commonheader page="Privacy Policy" />
        <div className="row pt-4 justify-content-center">
          <div className="-12 text-center">
            Please see the <a href="/misc/privacy.pdf">Privacy Policy</a> here.
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
