import Commonheader from "./components/headers/Common";
import BlueHeader from "./components/common/BlueHeader";

const NotFound = () => {
  return (
    <>
      <BlueHeader title="Not found" />
      <div className="container pb-5 pl-1 pr-1">
        <Commonheader page="404 Not Found" />
        <div className="row pt-4 justify-content-center">
          <div className="-12 text-center">
          Uh oh! The page your are looking for could not be found.
          </div>
          <div className="col-12 pt-4 text-center">
            Please double check the link, or <a href="/">click here</a> to go to
            the homepage.
            </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
