import React from 'react';
import { Button } from 'react-bootstrap';

function Error() {
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="container mt-5 mb-5">
      <div className="loading-text">Error...</div>
      <Button variant="primary" onClick={handleRefresh}>
        Reload Page...
      </Button>
    </div>
  );
}

export default Error;
