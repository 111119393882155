import React, { useEffect, useState, useCallback } from "react";
import OtherHeader from "./components/headers/Other";
import OnboardHeader from "./components/onboard/OnboardHeader";
import { setElementWidth } from "./components/utils/width";
import { TableRows } from "./components/onboard/TableRows";
import { useLocation } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import Loading from "./components/common/Loading";
import HelpHeader from "./components/onboard/HelpHeader";

const Onboard = () => {
  const [loadingAPI, setLoadingAPI] = useState(true);
  const [errorLoadingAPI, seterrorLoadingAPI] = useState(true);
  const [apiHasLoadedOnce, setapiHasLoadedOnce] = useState(false);

  const [apiData, setApiData] = useState(null);

  const [ShowHelpHeader, setShowHelpHeader] = useState(false);

  const [IsOutdated, setIsOutdated] = useState(false);

  const [lastUpdated, setLastUpdated] = useState(null);

  useEffect(() => {
    setElementWidth(".cp-dep");
    setElementWidth(".cp-plat");
    setElementWidth(".icon-column");
  }, [apiData]);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const rid = query.get("rid");
  const sourceCRS = query.get("sourceCRS");
  const destCRS = query.get("destCRS");
  const departureScheduled = query.get("departureScheduled");
  const trainOperator = query.get("trainOperator");
  const terminates = query.get("terminates");

  const handleCloseHelpHeader = () => {
    setShowHelpHeader(false);
  };

  const flashGreen = () => {
    const refreshData = document.getElementById("refresh-data");
    const tickRefreshData = document.getElementById("tick-refresh-data");
    const rotateIcon = document.getElementById("rotateicon");

    refreshData.classList.add("bg-green");
    tickRefreshData.classList.remove("d-none");
    rotateIcon.classList.add("d-none");

    setTimeout(() => {
      setAllowRefresh(true);
    }, 5000);

    setTimeout(() => {
      refreshData.classList.remove("bg-green");
      tickRefreshData.classList.add("d-none");
      rotateIcon.classList.remove("d-none");
    }, 1000);
  };

  const [allowRefresh, setAllowRefresh] = useState(true);

  const handleRefreshClick = () => {
    if (allowRefresh) {
      setAllowRefresh(false);
      fetchJourneyData();
    }

    const rotateIcon = document.getElementById("rotateicon");
    rotateIcon.classList.add("imageRot");
    rotateIcon.addEventListener("animationend", () => {
      rotateIcon.classList.remove("imageRot");
      flashGreen();
    });
  };

  const fetchJourneyData = useCallback(() => {
    const timeoutPromise = new Promise((resolve, reject) => {
      setTimeout(() => reject(new Error("Request timeout")), 6000);
    });

    Promise.race([
      fetch(
        `${process.env.REACT_APP_DBAPI_URL}/api/v1.0/getFormattedService?rid=${rid}&sourceCRS=${sourceCRS}&destCRS=${destCRS}`
      ),
      timeoutPromise,
    ])
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setApiData(data);
        setLoadingAPI(false);
        seterrorLoadingAPI(false);
        setLastUpdated(new Date());
        setapiHasLoadedOnce(true);
        if (data.isOutdated) {
          setIsOutdated(true);
        } else {
          setIsOutdated(false);
        }
      })
      .catch((error) => {
        seterrorLoadingAPI(true);
        setLoadingAPI(false);
      });
  }, [destCRS, rid, sourceCRS]);

  function isDocumentVisible() {
    // Using the Page Visibility API
    return document.visibilityState === "visible";
  }


  const conditionalRefresh = useCallback(() => {
    if (isDocumentVisible()) {
      fetchJourneyData();
    }
  }, [fetchJourneyData]);

  useEffect(() => {
    function handleVisibilityChange() {
      conditionalRefresh();
    }

    document.addEventListener("visibilitychange", handleVisibilityChange);

    const intervalId = setInterval(conditionalRefresh, 5 * 60 * 1000);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearInterval(intervalId);
    };
  }, [conditionalRefresh]);

  useEffect(() => {
    fetchJourneyData();
  }, [fetchJourneyData]);

  useEffect(() => {
    fetchJourneyData();
  }, [fetchJourneyData]);

  const handleHelpMenuClick = () => {
    setShowHelpHeader(true);
  };

  if (loadingAPI) return <Loading />;

  return (
    <>
      <OtherHeader url="onboard" name="Onboard" />
      <HelpHeader show={ShowHelpHeader} handleClose={handleCloseHelpHeader} />
      <OnboardHeader
        title="Journey Tracker"
        subtext={`Tracking the ${departureScheduled} ${trainOperator} service terminating at ${terminates}`}
        error={errorLoadingAPI}
        lastUpdated={lastUpdated}
      />
      {!IsOutdated ? (
        <>
          <div className="container mt-3">
            <div className="row justify-content-end">
              <div className="col-auto reduced-left-padding reduced-right-padding">
                <button
                  id="help"
                  type="button"
                  className="btn btn-ontheme align-items-center btn-block"
                  onClick={handleHelpMenuClick}
                >
                  <HelpIcon fontSize="small" />
                </button>
              </div>
              <div className="col-auto reduced-left-padding">
                <button
                  id="refresh-data"
                  type="button"
                  className="btn btn-ontheme align-items-center btn-block"
                  onClick={handleRefreshClick}
                >
                  <svg
                    id="rotateicon"
                    className="bi bi-arrow-clockwise"
                    width="1.3em"
                    height="1.3em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.17 6.706a5 5 0 017.103-3.16.5.5 0 10.454-.892A6 6 0 1013.455 5.5a.5.5 0 00-.91.417 5 5 0 11-9.375.789z"
                      clipRule="evenodd"
                    />
                    <path
                      fillRule="evenodd"
                      d="M8.147.146a.5.5 0 01.707 0l2.5 2.5a.5.5 0 010 .708l-2.5 2.5a.5.5 0 11-.707-.708L10.293 3 8.147.854a.5.5 0 010-.708z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <svg
                    id="tick-refresh-data"
                    className="bi bi-check d-none"
                    width="1.3em"
                    height="1.3em"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M13.854 3.646a.5.5 0 010 .708l-7 7a.5.5 0 01-.708 0l-3.5-3.5a.5.5 0 11.708-.708L6.5 10.293l6.646-6.647a.5.5 0 01.708 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
            {!errorLoadingAPI || apiHasLoadedOnce ? (
              <div>
                <div className="border-bottom mb-2 pt-4 pb-0">
                  <h5 className="mb-2">Journey Information</h5>
                </div>
                <div className="col-auto mb-2 pl-0">
                  {apiData.cancellationReason !== "" ? (
                    <span className="sts-red">
                      {apiData.cancellationReason}.
                    </span>
                  ) : apiData.delayReason !== "" ? (
                    <span className="sts-amber">{apiData.delayReason}.</span>
                  ) : (
                    <>
                      <span className="font-weight-bold">
                        Service Duration:{" "}
                      </span>{" "}
                      {apiData.duration}
                    </>
                  )}
                </div>

                <div className="border-bottom mb-2 pt-4 pb-0">
                  <h5 className="mb-2">Journey Progress</h5>
                </div>
                <div className="justify-content-left d-flex pb-2 pt-2 border-bottom">
                  <div className="text-center align-self-center icon-column d-flex flex-column align-items-center text-center mr-3">
                    <div className="font-weight-bold">Arr.</div>
                  </div>
                  <div className="text-center align-self-center icon-column d-flex flex-column align-items-center text-center mr-4">
                    <div className="font-weight-bold">Dep.</div>
                  </div>

                  <div className="col-pad-override col my-auto serviceinfo">
                    <div className="font-weight-bold">Station</div>
                  </div>
                  <div className="ml-auto text-center cp-plat justify-content-center align-content-center d-flex flex-column">
                    <div className="font-weight-bold">Plat.</div>
                  </div>
                </div>
                <div className="pb-5">
                  <TableRows apiData={apiData} />
                </div>
              </div>
            ) : (
              <div className="container mt-3">
                <div className="col-12 text-center">
                  Uh oh. There was an error loading the Shared Journey. This is
                  usually caused by a bad network connection or in some rarer
                  cases an issue with National Rail where we source our data.
                  Try hitting the refresh button above.
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="text-center">
          This journey has expired. Thanks for using departureboard.io!
        </div>
      )}
    </>
  );
};

export default Onboard;
