import React from "react";

function Deviated({ initialPage }) {
  const handleNavigation = (e) => {
    e.preventDefault();
    window.location.href = initialPage;
  };

  const handleClose = () => {
    localStorage.setItem("bannerClosed", "true");
    document.querySelector(".deviated-header").style.display = "none";
  };

  return (
    <>
      <div className="block-color-header-blue deviated-header container">
        <div className="row">
        <div className="col-11">
          <div className="pl-2">
            We noticed you've navigated away from the page you saved to your
            homescreen. Just {/* eslint-disable-next-line */}
            <a href="#" onClick={handleNavigation}>
              click here
            </a>{" "}
            to go back.
          </div>
        </div>
        <div className="col-1">
          <button className="close-btn pr-2" onClick={handleClose}>
            &times;
          </button>
        </div>
        
      </div>
      </div>
    </>
  );
}

export default Deviated;
