import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Commonheader from "./components/headers/Common";
import {
  auth,
  GoogleAuthProvider,
  signInWithPopup,
  OAuthProvider,
} from "./Firebase";

const Login = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const referURL = query.get("referURL");

  const [error, setError] = useState("");

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();

    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error("Error signing in with Google", error);
      setError(
        "An error occurred while signing in with Google. Please try again."
      );
    }
  };

  const signInWithApple = async () => {
    const provider = new OAuthProvider("apple.com");

    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error("Error signing in with Apple", error);
      setError(
        "An error occurred while signing in with Apple. Please try again."
      );
    }
  };

  const signInWithMicrosoft = async () => {
    const provider = new OAuthProvider("microsoft.com");

    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error("Error signing in with Microsoft", error);
      setError(
        "An error occurred while signing in with Microsoft. Please try again."
      );
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        if (referURL && referURL !== "") {
          window.location.href = referURL; // Assign new url to redirect
        } else {
          window.location.href = "/"; // Redirect to the homepage
        }
      }
    });

    return () => unsubscribe();
  }, [referURL]); // Don't forget to add referURL in your dependency array

  return (
    <div className="container-fluid pb-5">
      <Commonheader page="Login" />
      <div className="row justify-content-center">
        <div className="col-12 col-sm-10 col-md-8 col-lg-6">
          <h2 className="pt-5 pb-2">Login or Register</h2>
          <p>
            Log in to your departureboard.io account below to benefit from your{" "}
            <a href="/pro">departureboard.io Pro</a> features.
          </p>
          <p>
            If you don't have an existing account one will be created for you
            when you sign in with one of the supported providers below.
          </p>
          <div className="mt-4">
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            <button
              type="button"
              className="apple-sign-in w-100 mb-3 mt-2 btn"
              onClick={signInWithApple}
            >
              Sign in with Apple
            </button>

            <button
              type="button"
              className="google-sign-in btn w-100 mb-3"
              onClick={signInWithGoogle}
            >
              Sign in with Google
            </button>

            <button
              type="button"
              className="microsoft-sign-in w-100 btn"
              onClick={signInWithMicrosoft}
            >
              Sign in with Microsoft
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
