import Commonheader from "./components/headers/Common";
import BlueHeader from "./components/common/BlueHeader";

const Help = () => {
  return (
    <>
      <BlueHeader title="Help" />
      <div className="container pb-5 pl-1 pr-1">
        <Commonheader page="Help" />
        <div className="row pt-2 justify-content-center">
          <div className="col-12 pt-0 text-center">
            For support, please drop an email to{" "}
            <a href="mailto:support@departureboard.io">
              support@departureboard.io
            </a>
            <p className="pt-4">
              If it is an account or Pro releated issue, please send the email
              from your registered email address.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Help;
