import React from "react";

function NoServices() {
  return (
    <div className="mt-5 text-center">
      <p>
        Oh no! There's currently no services running between these two stations!
      </p>

      <p>You can still add the journey to your Home Screen for later.</p>

      <p>
        <span className="font-weight-bold">Remember: </span>
        We only show services that go directly between stations. Try checking if
        you can get to your destination by changing trains.
      </p>
    </div>
  );
}

export default NoServices;
