import React from "react";

const BlueHeader = ({ title, subtext }) => {
  return (
    <div className="pb-4">
      <div className="header-wrapper">
        <div className="block-color-header-blue journey-result-header">
          <div className="block-color-header-heading shared-header">
            {title}
          </div>
          {(subtext && subtext !== "") && <div className="subheader ml-3 mr-3">{subtext}</div>}
        </div>
      </div>
    </div>
  );
};

export default BlueHeader;
