import React from 'react';

function Departuredue({ journey }) {
  const isOnTime = journey.departureDue === "On time";
  const isCancelled = journey.departureDue === "Cancelled";
  const isUnknown = journey.departureDue === "Unknown" || journey.departureDue === "Delayed";
  const className = isOnTime
    ? "depart-due sts-green"
    : isCancelled
    ? "depart-due sts-red"
    : "depart-due sts-amber";
  const content = isOnTime
    ? journey.departureDue
    : isCancelled
    ? `${journey.departureDue}`
    : isUnknown
    ? "Delayed"
    : `${journey.departureDue}`;

  return <div className={className}>{content}</div>;
}

export default Departuredue;
