import React from "react";

function Stoptrainpromo() {
  return (
    <div className="featured-bg">
      <div className="container">
        <div className="row">
          <div className="align-self-center col-md-12 col-lg-6">
            <h2>Spend 5 minutes to save hours?</h2>
            <p style={{ paddingTop: "0.1rem" }}>
              Watch this video to learn more, and we'll show you how.
            </p>
            <p
              className="d-none d-md-block"
              style={{ paddingTop: "0.8rem" }}
            ></p>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="embed-responsive-item"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                src="https://www.youtube.com/embed/Pfi4ERsDPLc"
                title="YouTube video player"
              ></iframe>
            </div>
          </div>
          <div className="col-md-12 d-block d-md-none">
            <p style={{ paddingTop: "0.8rem" }}></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stoptrainpromo;
