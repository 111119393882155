import React from "react";
import { Helmet } from "react-helmet";

const Commonheader = ({ page }) => {
  return (
    <Helmet>
      <title>{page} | departureboard.io</title>

      <link rel="manifest" href="/manifest/site.webmanifest" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="apple-mobile-web-app-title" content="departureboard.io" />
      <meta name="application-name" content="departureboard.io" />
      <meta name="og:title" content={`${page} | departureboard.io`} />
      <meta name="og:url" content="https://departureboard.io/" />
      <meta
        property="og:description"
        content="Live train departure information for all train services in the United Kingdom. Live and up to date with the on-platform departure boards."
      />
      <meta
        name="description"
        content="Live train departure information for all train services in the United Kingdom. Live and up to date with the on-platform departure boards."
      />
    </Helmet>
  );
};

export default Commonheader;
