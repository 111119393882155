import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { auth as firebaseAuth } from "../../Firebase";
import "firebase/auth";
import DeviceUtils from "../utils/deviceType";

const OtherHeader = ({ url, name, page }) => {
  
  const [webManifestUrl, setWebManifestUrl] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authCheckComplete, setAuthCheckComplete] = useState(false);

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
        user.getIdToken().then((token) => {
          const manifestUrl = `${process.env.REACT_APP_DBAPI_URL}/api/v1.0/generateWebManifest.json?url=${url}&authtoken=${token}`;
          setWebManifestUrl(manifestUrl);
        });
      } else {
        setIsLoggedIn(false);
        setWebManifestUrl("");
      }
      setAuthCheckComplete(true);
    });

    return () => {
      unsubscribe();
    };
  }, [url]);

  return (
    <Helmet>
      <title>
        {`${name} |
        departureboard.io`}
      </title>
      <meta name="apple-mobile-web-app-title" content={name} />
      <meta name="application-name" content={name} />
      <meta name="og:title" content={`${name} | departureboard.io`} />
      <meta name="og:url" content={window.location.href} />
      <meta
        property="og:description"
        content="Live train departure information for all train services in the United Kingdom. Live and up to date with the on-platform departure boards."
      />
      <meta
        name="description"
        content="Live train departure information for all train services in the United Kingdom. Live and up to date with the on-platform departure boards."
      />

      {authCheckComplete ? (
        isLoggedIn && webManifestUrl && DeviceUtils.isIOS() ? (
          <link rel="manifest" href={webManifestUrl} />
        ) : (
          <link rel="manifest" href="/manifest/site.webmanifest" />
        )
      ) : null}
    </Helmet>
  );
};

export default OtherHeader;
