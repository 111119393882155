import React from "react";
import TimeSinceUpdate from "../journey/micro/LastUpdated";

const OnboardHeader = ({
  title,
  subtext,
  lastUpdated,
  error,
  showSearch,
  noDocument,
  isOutdated,
}) => {
  return (
    <div className="pb-0">
      <div className="header-wrapper pb-1 pt-3">
        <div className="block-color-header-blue journey-result-header">
          <div className="block-color-header-heading shared-header">
            {title}
          </div>
          {subtext && subtext !== "" && (
            <div className="subheader ml-3 mr-3">{subtext}</div>
          )}
        </div>
        {!error && !showSearch && !noDocument && !isOutdated ? (
          <div className="block-color-header-small pb-3 pt-3">
            <div>
              Last updated <TimeSinceUpdate lastUpdated={lastUpdated} />
            </div>
          </div>
        ) : error && !showSearch && !noDocument && !isOutdated ? (
          <div className="block-color-header-small pb-3 pt-3">
            <div className="text-center">
              ❗ Refresh failed, please check your network
              <br /> (last updated <TimeSinceUpdate lastUpdated={lastUpdated} />
              )
            </div>
          </div>
        ) : (
          <div className="pb-3"></div>
        )}
      </div>
    </div>
  );
};

export default OnboardHeader;
