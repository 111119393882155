import { useEffect, useState, useCallback } from "react";
import { setElementWidth } from "../utils/width";
import DeparturedueCP from "./micro/DepartureDueCP";
import { useAuth } from "../../contexts/Authcontext";
import {
  renderCallingPointPlatform,
  renderCallingPointPlatformTitle,
} from "../utils/platformUtils";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import ReactGA from "react-ga4"; // import ReactGA
import { useLocation } from "react-router-dom";

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID); // initialize Google Analytics with your tracking ID

const ExpandService = ({ expanded, journey }) => {
  const [ISRID, setISRID] = useState(false);
  const [ISRIDLoaded, setISRIDLoaded] = useState(false);
  const { currentUser } = useAuth(); // Use the hook to get currentUser and userDocument
  const location = useLocation();

  useEffect(() => {
    setElementWidth(".cp-dep");
    setElementWidth(".cp-plat");
  }, [journey, expanded]);

  // setOnboardDocument set the RID of the onboard service
  const setOnboardDocument = useCallback(async () => {
    const firestore = getFirestore();
    const docRef = doc(firestore, "Users", currentUser.uid, "onboard", "trip");
    const journeyData = {
      rid: journey.rid,
      sourceCRS: journey.sourceCRS,
      destCRS: journey.destCRS,
      departureScheduled: journey.departureScheduled,
      trainOperator: journey.trainOperator,
      terminates: journey.trainTerminates,
      sourceName: journey.sourceFullName,
      destName: journey.destFullName,
      timestamp: new Date(),
    };
    await setDoc(docRef, journeyData);
    setISRID(true);
    ReactGA.event("im_onboard_button_click", {
      page_title: window.location.origin + location.pathname, // Specify the page title
      page_location: window.location.origin + location.pathname, // Specify the page location without query parameters
    });
  }, [journey, currentUser, location.pathname]);

  // deleteOnboardDocument deletes a favourited RID
  const deleteOnboardDocument = useCallback(async () => {
    const firestore = getFirestore();
    const docRef = doc(firestore, "Users", currentUser.uid, "onboard", "trip");
    await deleteDoc(docRef);
    setISRID(false);
    ReactGA.event("im_not_onboard_button_click", {
      page_title: window.location.origin + location.pathname, // Specify the page title
      page_location: window.location.origin + location.pathname, // Specify the page location without query parameters
    });
  }, [currentUser, location.pathname]);

  const checkOnboardDocument = useCallback(async () => {
    const firestore = getFirestore();
    const docRef = doc(firestore, "Users", currentUser.uid, "onboard", "trip");
    const fav = await getDoc(docRef);
    if (fav.exists()) {
      if (fav.data().rid === journey.rid) {
        setISRID(true);
      } else {
        setISRID(false);
      }
    } else {
      setISRID(false);
    }
    setISRIDLoaded(true);
  }, [journey, currentUser]);

  useEffect(() => {
    checkOnboardDocument();
  }, [checkOnboardDocument, deleteOnboardDocument, setOnboardDocument]);

  const renderTableRows = () => {
    return journey.callingPoints.map((point, index) => (
      <div className="justify-content-left d-flex pb-3" key={index}>
        <div className="text-center mr-3 cp-dep">
          <div className="p-9 align-self-center">
            <span className="depart-time text-center">{point.scheduled}</span>
          </div>
          <div className="p-9 align-self-center">
            <span className="depart-due sts-green text-center">
              <DeparturedueCP
                cp={point}
                detectedCancelType={journey.detectedCancelType}
              />
            </span>
          </div>
        </div>
        <div>
          <div className="p-9 align-self-center">
            <span className="train-final-dest">{point.stationName}</span>
          </div>
          <div className="p-9 align-self-center">
            <span className="train-user-journey-dest">Stop {index + 1}</span>
          </div>
        </div>
        <div className="ml-auto text-center cp-plat">
          <div className="p-9 align-self-center">
            <span className="plat-title">
              {renderCallingPointPlatformTitle(point)}
            </span>
          </div>
          <div className="p-9 align-self-center">
            <span className="plat-number">
              {renderCallingPointPlatform(point)}
            </span>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="pt-3 pl-3 pr-3">
      {(journey.trainDivides ||
        journey.trainJoins ||
        journey.nrCancelReason ||
        journey.nrDelayReason) && (
        <>
          <div className="border-bottom mb-2 pl-0 pb-0">
            <h5 className="mb-1">Notes</h5>
          </div>
          <div className="col-auto pl-0 mb-3">
            {journey.trainDivides && (
              <div className="pb-1">
                This train is a dividing service and will divide at{" "}
                {journey.trainDividesAt}. Please ensure you are in the correct
                carriage.
              </div>
            )}
            {journey.trainJoins && (
              <div className="pb-1">
                This train is a joins another service at {journey.trainJoinsAt}.
                Please ensure you are in the correct carriage.
              </div>
            )}
            {journey.nrCancelReason !== "" ? (
              <div className="pb-1">
                <span className="sts-red">{journey.nrCancelReason}.</span>
              </div>
            ) : (
              journey.nrDelayReason !== "" && (
                <div className="pb-1">
                  <span className="sts-amber">{journey.nrDelayReason}.</span>
                </div>
              )
            )}
          </div>
        </>
      )}

      <div className="border-bottom mb-2 pb-0">
        <h5 className="mb-1">Calling Points</h5>
      </div>
      {renderTableRows()}
      <div className="border-bottom mb-2 pb-0 pt-2">
        <h5 className="mb-1">Onboard</h5>
      </div>
      {journey.trainDivides && (
        <div className="col-auto reduced-right-padding reduced-left-padding mb-3">
          We currently do not support services that divide in the Onboard Hub.
          If you're a Pro member and regularly catch this service please get in
          touch and let us know so we can prioritise support for dividing
          trains!
        </div>
      )}
      {journey.trainJoins && (
        <div className="col-auto reduced-right-padding reduced-left-padding mb-3">
          We currently do not support services that join in the Onboard Hub. If
          you're a Pro member and regularly catch this service, get in touch and
          let us know so we can prioritise support for dividing trains!
        </div>
      )}
      {ISRIDLoaded && !journey.trainDivides && !journey.trainJoins && (
        <div className="col-auto reduced-right-padding reduced-left-padding mb-3">
          <span className="pt-0 mt-0">
            Marking yourself as onboard will make this service available in the{" "}
            <a href="/onboard">Onboard Hub</a> where you can track this service
            as it progresses and share with family or friends.
          </span>
          <button
            id="setOnboard"
            type="button"
            className={`btn align-items-center btn-block mb-0 mt-2 btn-ontheme ${
              ISRID ? "green-button" : "btn-ontheme"
            }`}
            onClick={ISRID ? deleteOnboardDocument : setOnboardDocument}
          >
            {ISRID ? "I'm Onboard ✓" : "I'm Onboard"}
          </button>
        </div>
      )}
    </div>
  );
};

export default ExpandService;
