import React from "react";

function NotPro({ currentUser, userDocument }) {
  let myURL = "/pro?referURL=" + encodeURIComponent(window.location.href);
  let loginURL = "/login?referURL=" + encodeURIComponent(window.location.href);

  return (
    <>
      {!currentUser ? (
        <div className="mr-4 ml-4 pt-5">
          <p className="text-center pt-1">
            Uh oh! You need to be subscribed to departureboard.io Pro to see
            this section.
          </p>
          <p className="text-center pt-0">
            <a href={myURL}>Learn more and Subscribe</a>
          </p>

          <p className="text-center pt-1">
            Already Pro? <a href={loginURL}>Click here</a> to login.
          </p>
        </div>
      ) : (
        <div className="mr-4 ml-4 pt-5">
          <p className="text-center pt-1">
            Uh oh! You need to be subscribed to departureboard.io Pro to see
            this section.
          </p>
          <p className="text-center pt-0">
            <a href={myURL}>Learn more and Subscribe</a>
          </p>
        </div>
      )}
    </>
  );
}

export default NotPro;
