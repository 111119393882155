function DeparturedueCP({ cp, detectedCancelType }) {
  const isOnTime = cp.due === "On time";
  const isCancelled = (cp.isCancelled || detectedCancelType === "ENTIRE_SERVICE_CANCELLED");
  const isUnknown = cp.due === "Unknown" || cp.due === "Delayed";
  const className = isCancelled
    ? "depart-due sts-red"
    : isOnTime
    ? "depart-due sts-green"
    : "depart-due sts-amber";
  const content = isCancelled
    ? "Cancelled"
    : isOnTime
    ? cp.due
    : isUnknown
    ? "Delayed"
    : `Exp: ${cp.due}`;

  return <span className={className}>{content}</span>;
}

export default DeparturedueCP;
