import React, { useState, useEffect } from "react";
import moment from "moment";

const TimeSinceUpdate = ({ lastUpdated }) => {
  
  const [time, setTime] = useState(Date.now()); // dummy state

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(Date.now()); // Update the time state every second, which triggers re-render
    }, 1000);

    // Clear the interval when the component unmounts.
    return () => {
      clearInterval(timer);
    };
  }, [time]); // Add time as a dependency here

  return <span>{moment(lastUpdated).fromNow()}</span>;
};

export default TimeSinceUpdate;
