import React, { useState, useEffect } from "react";
import {
  collection,
  onSnapshot,
  query,
  getFirestore,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { useAuth } from "./contexts/Authcontext";
import BlueHeader from "./components/common/BlueHeader";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import StarIcon from "@mui/icons-material/Star";
import OtherHeader from "./components/headers/Other";
import NotPro from "./components/common/NotPro";

const Favourites = () => {
  const { currentUser, userDocument } = useAuth();
  const [favouriteJourneys, setFavouriteJourneys] = useState([]);

  useEffect(() => {
    if (currentUser) {
      const firestore = getFirestore();
      const favJourneysRef = collection(
        firestore,
        `Users/${currentUser.uid}/favouriteJourneys`
      );

      const unsubscribe = onSnapshot(query(favJourneysRef), (querySnapshot) => {
        let journeys = [];
        querySnapshot.forEach((doc) => {
          journeys.push({ id: doc.id, ...doc.data() });
        });
        setFavouriteJourneys(journeys);
      });

      return () => unsubscribe();
    }
  }, [userDocument, currentUser]);

  // deleteFavouriteDocument deletes a favourited journey
  const deleteFavouriteDocument = async (sourceCRS, destCRS) => {
    const firestore = getFirestore();
    const docRef = doc(
      firestore,
      "Users",
      currentUser.uid,
      "favouriteJourneys",
      sourceCRS + destCRS
    );
    await deleteDoc(docRef);
  };

  // navigateJourney deletes a favourited journey
  const navigateJourney = async (sourceCRS, destCRS, reverse) => {
    if (reverse) {
      window.location.href = `/journey/${destCRS}/${sourceCRS}`;
    } else {
      window.location.href = `/journey/${sourceCRS}/${destCRS}`;
    }
  };

  if (!currentUser || !userDocument || !userDocument.isPro)
    return <NotPro currentUser={currentUser} userDocument={userDocument} />;

  return (
    <>
      <OtherHeader url="favourites" name="Favourites" />
      <BlueHeader
        title="Favourites Hub"
        subtext="Quickly access your favourite journeys"
      />
      <div className="container-fluid pb-5">
        <div className="row justify-content-center  pl-1 pr-1">
          <div className="col-md-6">
            <div className="row">
              {favouriteJourneys.length > 0 ? (
                favouriteJourneys.map((journey, index) => (
                  <div className="col-12 border-grey" key={index}>
                    <div className="col-12 pt-2 pb-2" key={index}>
                      <div className="row align-items-center ">
                        <div className="col-1 pl-0 pr-0 text-center">
                          <button
                            id="favouriteJourney"
                            type="button"
                            className="btn btn-noback ml-0 mr-0 mt-0 mb-0 pl-0 pr-0 pt-0 pb-0 align-items-center text-center btn-block"
                            onClick={() =>
                              deleteFavouriteDocument(
                                journey.sourceCRS,
                                journey.destCRS
                              )
                            }
                          >
                            <StarIcon style={{ color: "gold", fontSize: 40 }} />
                          </button>
                        </div>
                        <div className="col text-center pr-0">
                          <span className="font-weight-bold">
                            {journey.sourceName}
                          </span>
                        </div>
                        <div className="col-1 text-center pl-0 pr-0">to</div>
                        <div className="col text-center pl-0">
                          <span className="font-weight-bold">
                            {journey.destName}
                          </span>
                        </div>
                        <div className="col-1 pl-0 pr-0 text-center col-2">
                          <div className="row mb-2">
                            <div className="col">
                              <button
                                type="button"
                                className="btn btn-ontheme align-items-center btn-block pt-1 pl-0 pr-0 d-flex justify-content-center align-items-center"
                                onClick={() =>
                                  navigateJourney(
                                    journey.sourceCRS,
                                    journey.destCRS,
                                    false
                                  )
                                }
                              >
                                <ArrowForwardIcon />
                              </button>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <button
                                type="button"
                                className="btn btn-ontheme align-items-center btn-block pt-1  pl-0 pr-0 d-flex justify-content-center align-items-center"
                                onClick={() =>
                                  navigateJourney(
                                    journey.sourceCRS,
                                    journey.destCRS,
                                    true
                                  )
                                }
                              >
                                <SwapHorizIcon />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <div className="col-12 text-center">
                    Uh oh. No favourite journeys found.
                  </div>
                  <div className="col-12 text-center pt-4">
                    Search for your journey and hit the{" "}
                    <StarIcon fontSize="small" /> to add to the Favourites Hub
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Favourites;
