// useMobileOperatingSystem.js
import { useEffect, useState } from "react";

function useMobileOperatingSystem() {
  const [os, setOS] = useState("unknown");

  useEffect(() => {
    function getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "android";
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "ios";
      }

      return "unknown";
    }

    setOS(getMobileOperatingSystem());
  }, []);

  return os;
}

export default useMobileOperatingSystem;
