import React, {useEffect} from 'react';

import Searchbox from "./components/home/searchbox/Searchbox";
import Stoptrainpromo from "./components/home/stoptrainpromo/Stoptrainpromo";
import Howweredifferent from "./components/home/howweredifferent/Howweredifferent";
import OneIcon from './components/home/oneicon/OneIcon';

import Commonheader from "./components/headers/Common";

const Journey = ({ match }) => {

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 100);
  }, []);


  return (
    <div>
      <Commonheader page="Home" />
      <Searchbox />
      <OneIcon />
      <Howweredifferent />
      <Stoptrainpromo />
    </div>
  );
};

export default Journey;
