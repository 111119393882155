import { Modal, Button } from "react-bootstrap";
import DepartureDueDelay from "../assets/img/DepartureDueDelay.svg";
import DepartureHappened from "../assets/img/DepartureHappened.svg";
import DepartureDueOnTime from "../assets/img/DepartureDueOnTime.svg";
import ArrivalDueOnTime from "../assets/img/ArrivalDueOnTime.svg";
import ArrivalDueDelay from "../assets/img/ArrivalDueDelay.svg";
import ArrivalHappened from "../assets/img/ArrivalHappened.svg";
import DepartDalayed from "../assets/img/DepartDelayed.svg";
import ArriveDelayed from "../assets/img/ArriveDelayed.svg";
import ArrivalCancelled from "../assets/img/ArrivalCancelled.svg";
import DepartureCancelled from "../assets/img/DepartureCancelled.svg";

function HelpHeader({ show, handleClose }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      id="addtoHomescreen"
      aria-labelledby="addtoHomescreen"
      aria-hidden="true"
    >
      <Modal.Header>
        <Modal.Title>Onboard Hub</Modal.Title>
        <Button
          variant="none"
          className="close"
          onClick={handleClose}
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-add-body pt-1">
          <div className="pb-4">
            The below table explains the icons used in the Onboard Hub.
          </div>
          <table className="table">
            <tr>
              <td className="align-middle">
                <img
                  src={ArrivalDueOnTime}
                  alt="Train arrival due on time icon"
                />
              </td>
              <td className="align-middle text-left">
                Service has not arrived but is scheduled to do so on time
              </td>
            </tr>
            <tr>
              <td className="align-middle">
                <img src={DepartureDueOnTime} alt="Train departure due on time icon" />
              </td>
              <td className="align-middle text-left">
                Service has not departed but is scheduled to do so on time
              </td>
            </tr>
            <tr>
              <td className="align-middle">
                <img src={ArrivalDueDelay } alt="Train arrival due delayed icon" />
              </td>
              <td className="align-middle text-left">
                Service has not arrived and is due to arrive late
              </td>
            </tr>
            <tr>
              <td className="align-middle pr-2 pl-2">
                <img src={DepartureDueDelay} alt="Train departure due delayed icon" />
              </td>
              <td className="align-middle text-left">
                Service has not departed and is due to depart late
              </td>
            </tr>
            <tr>
              <td className="align-middle">
                <img src={ArrivalCancelled } alt="Train arrival cancelled icon" />
              </td>
              <td className="align-middle text-left">
                This service is cancelled and will not arrive
              </td>
            </tr>
            <tr>
              <td className="align-middle">
                <img src={DepartureCancelled } alt="Train departure cancelled icon" />
              </td>
              <td className="align-middle text-left">
                This service is cancelled and will not depart
              </td>
            </tr>

            <tr>
              <td className="align-middle">
                <img src={ArrivalHappened} alt="Train has arrived icon"/>
              </td>
              <td className="align-middle text-left">
                Service arrived on time
              </td>
            </tr>
            <tr>
              <td className="align-middle">
                <img src={DepartureHappened} alt="Train has departed icon" />
              </td>
              <td className="align-middle text-left">
                Service departed on time
              </td>
            </tr>
            <tr>
              <td className="align-middle">
                <img src={ArriveDelayed} alt="Train has arrived late icon" />
              </td>
              <td className="align-middle text-left">Service arrived late</td>
            </tr>
            <tr>
              <td className="align-middle">
                <img src={DepartDalayed} alt="Train has departed late icon" />
              </td>
              <td className="align-middle text-left">Service departed late</td>
            </tr>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default HelpHeader;
